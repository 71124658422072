import { useNavigation, NavigationHelpersContext, useRoute } from "@react-navigation/native";
import React, { Component, useState } from "react";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  Alert,
  View,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import { HeaderText, BodyText } from "../components/Text";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { useAuth } from "../hooks/useAuth";
import FFAPasswordField from "../components/FFAPasswordField";
import FFAButton from "../components/FFAButton";
import i18n from 'i18n-js';
import { DefaultTheme } from "../theme/DefaultTheme";
import SnackBar from 'react-native-snackbar-component'

// import Snackbar from 'react-native-snackbar';

const ChangePassword = (props) => {
  const {navigate} = useNavigation();
  const {changePassword, signOut} = useAuth();
  const route = useRoute();
  const { username } = route.params;

  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarVisible, setSnackBarVisible] = useState(false);
  
  const setInfoMessage = (error, message) => {
    setError(error);
    setSnackBarVisible(true);
    setMessage(message);
  }

  const confirmationCallBack = () =>{
    signOut();
    setIsLoading(false);
    setInfoMessage(true, 'Changed password successfully.');
    navigate("login");
  }

  const changePasswordHandler = async() => {
    setShowMessage(true);
    if(newPassword.length === 0){
      setInfoMessage(true, 'New Password should not be blank');
    } else if(oldPassword.length === 0){
      setInfoMessage(true, 'Old Password should not be blank');
    } else {
      setIsLoading(true);
      changePassword(username,oldPassword, newPassword, confirmationCallBack);
      
    } 
  };


  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
      <Header title={i18n.t('changePassword')} showBack />
      <View style={DefaultTheme.container}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={{ marginVertical: 5 }} />
          <BodyText>{message}</BodyText>
          <SnackBar visible={snackBarVisible} textMessage={message} bottom/>
          <BodyText>{i18n.t('username')}</BodyText>
          <TextInput textContentType="username" editable={false} maxLength={15}  value = {username}/>
          <View style={{ marginVertical: 15 }} />
          <BodyText>{i18n.t('oldPassword')}</BodyText>
          <FFAPasswordField maxLength={15} onChangeText = {value => setOldPassword(value)}/>
          <View style={{ marginVertical: 15 }} />
          <BodyText>{i18n.t('newPassword')}</BodyText>
          <FFAPasswordField maxLength={15} onChangeText = {value => setNewPassword(value)}/>
          <View style={{ marginVertical: 15 }} />
          

          <FFAButton title= {i18n.t('changePassword')} isLoading = {isLoading} onPress={() => changePasswordHandler()}/>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

export default ChangePassword;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    paddingTop: Platform.OS == "ios" ? 20 : StatusBar.currentHeight + 15,
    paddingHorizontal: 25,
  },
});
