import React, { useEffect, useState } from 'react';
import Dropdown from '../../components/Dropdown';
import FlexContainer from '../../components/FlexContainer';
import i18n from 'i18n-js';
import Calender from '../../components/Calender';
import Constants from './constants';
import Button from '../../components/Button';
const UserListFilter = ({
    onFilterChange,
    onFilterReady
}) => {
    const [canEdit, setFilterEditability] = useState(true);
    const currentDate = new Date();
    const defaultFromDate = new Date();
    defaultFromDate.setDate(currentDate.getDate() - (Constants.lastNDaysHistoricalData - 1));
    const [fromDate, setFromDate] = useState(new Date(defaultFromDate));
    const [toDate, setToDate] = useState(currentDate);

    const onApplyFilter = () => {
        if (onFilterChange) {
            onFilterChange({
                location,
                controller,
                collector,
                fromDate,
                toDate,
                dataType: dataType || 'all'
            });
        }
    };
    const onResetFilter = () => {
        setLocation('');
        setController('');
        setCollector('');
    };

    const filterOptions = [
        {
            component: Dropdown,
            bold: true,
            valueFontSize: 13,
            items: [{
                key: 'all',
                name: 'All'
            }],
            isVisible: true,
            itemKey: 'key',
            displayValueKey: 'name',
            label: i18n.t('dataType'),
            isEditable: true,
            selectedKey: 'all',
        },
        {
            component: Calender,
            bold: true,
            valueFontSize: 13,
            isVisible: true,
            label: i18n.t('toDate'),
            isEditable: canEdit,
            id: 'toDatePicker',
            onValueChange: (date) => setToDate(date),
            selectedDate: toDate ? toDate.toLocaleDateString() : '',
            minDate: fromDate,
            maxDate: currentDate
        },
        {
            component: Calender,
            bold: true,
            valueFontSize: 13,
            isVisible: true,
            label: i18n.t('toDate'),
            isEditable: canEdit,
            id: 'toDatePicker',
            onValueChange: (date) => setToDate(date),
            selectedDate: toDate ? toDate.toLocaleDateString() : '',
            minDate: fromDate,
            maxDate: currentDate
        },
        {
            component: Button,
            title: i18n.t('editFilter'),
            onPress: () => setFilterEditability(!canEdit),
            primary: true,
            btnStyle: { paddingRight: 5, borderRadius: 0, minHeight: 58 },
            icon: require("../../assets/images/search-3-32.png"),
            iconEnd: true
        }
    ];

    onFilterReady(true);

    return (
        <FlexContainer margin={5} paddingTop={10} paddingBottom={10}>
            {filterOptions.map(option => {
                return <option.component {...option} />;
            })}
        </FlexContainer>
    );
};

export default UserListFilter;
