import { useNavigation, NavigationHelpersContext } from "@react-navigation/native";
import React, {useState } from "react";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  View,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import { BodyText, InfoText } from "../components/Text";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import { useAuth } from "../hooks/useAuth";
import TextLink from "../components/TextLink";
import FFAButton from "../components/FFAButton";
import i18n from 'i18n-js';
import { DefaultTheme } from "../theme/DefaultTheme";
import FFAPasswordField from "../components/FFAPasswordField";

const ForgetPassword = (props) => {
  const {navigate} = useNavigation();
  const {forgotPassword, forgotPasswordSubmit} = useAuth();


  const [username, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const [authCode, setAuthCode] = useState(null);
  const [message, setMessage] = useState(null);
  const [isConfirmedCode, setConfirmedCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const onChangeText = (key, value) =>{
    this.setState({
      [key]: value
    })
  }

  const setInfoMessage = (error, message) => {
    setIsError(error);
    setMessage(message);
  }

  const setErrorResponse = (err)=>  {
    if (!err.message) {
      setIsError(true);
      setMessage(err);
    } else {
      setIsError(true);
      setMessage(err.message);
    }
  }

  const confirmationCallBack = () =>{
    setMessage("Enter the confirmation code and password");
    setConfirmedCode(true);
    setIsLoading(false);
  }

  const errorCallBack = (err) =>{
    setErrorResponse(err);
  }

  const forgotPasswordHandler = async() => {
    setShowMessage(true);
    if(username === null){
      setInfoMessage(true, 'Enter user name');
    } else{
      setIsLoading(true);
      forgotPassword(username, confirmationCallBack, errorCallBack);
    }


  };

 const forgotPasswordSubmitHandler = async() =>{
    setIsLoading(true);
    forgotPasswordSubmit(username, authCode, password);
    setIsLoading(false);
    navigate("login");
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
      <Header title={i18n.t('forgotPassword')} showBack click={true}/>
      <View style={DefaultTheme.container}>
        <ScrollView showsVerticalScrollIndicator={false}>

          <View style={{ marginVertical: 5 }} />
          {showMessage && <InfoText error={isError}>{message}</InfoText>}
          <View style={{ marginVertical: 5 }} />
          <BodyText >{i18n.t('username')}</BodyText>
          <TextInput textContentType="username" editable={!isConfirmedCode} maxLength={15}  onChangeText = {value => setUserName(value)}/>
          <View style={{ marginVertical: 5 }} />
          <BodyText >{i18n.t('confirmationCode')}</BodyText>
          <TextInput keyboardType="numeric" editable={isConfirmedCode} maxLength={6} onChangeText = {value => setAuthCode(value)}/>
          <View style={{ marginVertical: 5 }} />
          <BodyText >{i18n.t('newPassword')}</BodyText>
          {/* <TextInput textContentType="password" editable={isConfirmedCode} secureTextEntry={true} maxLength={15} onChangeText = {value => setPassword(value)}/> */}
          <FFAPasswordField maxLength={15} editable={isConfirmedCode} onChangeText = {value => setPassword(value)}/>
          <View style={{ marginVertical: 5 }} />
          
          {
              !isConfirmedCode ? 
              <BodyText bold={true} right={true}>{i18n.t('resendCode')}</BodyText> 
              :
              <TextLink
                disabled={isConfirmedCode}
                title={i18n.t('resendCode')}
                onPress={() => forgotPasswordHandler()}
                primary={'true'}
                right={true}
                bold={'true'}
              />

            }

          {/* <TextLink
                bold={true}
                title={i18n.t('resendCode')}
                onPress={() => this.confirmationCallBack()}
                primary={true}
                right={true}
              /> */}
          <View style={{ marginVertical: 5 }} />    
          <FFAButton
            title= {isConfirmedCode ? i18n.t('verify') : i18n.t('forgotPassword')}
            isLoading={isLoading}
            disabled={isLoading}
            onPress={() => {isConfirmedCode ? forgotPasswordSubmitHandler() : forgotPasswordHandler()}}
            
          />
          <View style={{ marginVertical: 5 }} />

          
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

export default ForgetPassword;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    paddingTop: Platform.OS == "ios" ? 20 : StatusBar.currentHeight + 15,
    paddingHorizontal: 25,
  },
});
