import React, {useState, useEffect} from "react";
import { useRoute, useNavigation } from "@react-navigation/native";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { HeaderText, BodyText, InfoText } from "../components/Text";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import {isDesktop, isMobile} from '../constants/common';
import { useAuth } from "../hooks/useAuth";
import { DefaultTheme } from "../theme/DefaultTheme";
import FFAButton from "../components/FFAButton";
import i18n from 'i18n-js';
import Helper from "../utils/Helper";

const EditPersonalInfoScreen = () => {
  const {editProfile, uploadImage} = useAuth();
  const navigation = useNavigation();

  const route = useRoute();
  const { apiCurrentUser } = route.params;
  const [name, setName] = useState(apiCurrentUser.personalData.name);
  const [middleName, setMiddleName] = useState(apiCurrentUser.personalData.middleName);
  const [familyName, setFamilyName] = useState(apiCurrentUser.personalData.familyName);
  const [website, setWebsite] = useState(apiCurrentUser.personalData.website);
  const [email, setEmail] = useState(apiCurrentUser.accountData.email);
  const [phoneNumber] = useState(apiCurrentUser.accountData.phoneNo);
  const [birthDate, setBirthDate] = useState(apiCurrentUser.personalData.birthdate);

  const [avatarPreview, setAvatarPreview] = useState(null);
  const [avatarPreview64, setAvatarPreview64] = useState(null);
  const [newProfilePic, setNewProfilePic] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const {profileImage} = useAuth();


  useEffect(() => {
    (async () => {

      const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
      if (status !== 'granted' && !isDesktop) {
        alert('Sorry, we need camera roll permissions to make this work!');
      }
    })();
  }, [avatarPreview]);

  const pickImage = async () => {
    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      quality: 1,
      base64:true,
    });

    if (!pickerResult.cancelled) {
      setNewProfilePic(true);
      setAvatarPreview(pickerResult.uri);
      setAvatarPreview64(pickerResult.base64);
    }
  };

  const handleOnSave = async () => {
    setLoading(true);
    setShowMessage(true);
    if(name.length ==0){
      setError(true);  
      setMessage('First name should not be blank.');
    }else if(familyName.length ==0){
      setError(true);  
      setMessage('Family Name should not be blank.');
    }else if(birthDate.length > 0 && !Helper.isDateValid(birthDate)){
      setError(true);  
      setMessage('Enter correct birth date');
    }else if(!Helper.isValidCharacter(name)){
      setError(true);  
      setMessage('Special character are not allowed in Name field.');
    }else if(middleName.length != 0 &&  !Helper.isValidCharacter(middleName)){
      setError(true);  
      setMessage('Special character are not allowed in Middle Name field.');
    }else if(!Helper.isValidCharacter(familyName)){
      setError(true);  
      setMessage('Special character are not allowed in Family Name field.');    
    }
    else{
      await editProfile(apiCurrentUser.username, name, middleName, familyName, email, website, birthDate, phoneNumber);
      if(newProfilePic){
          const image = isMobile ? avatarPreview64 : avatarPreview;
        await uploadImage(image);
      }
      setLoading(false);
      navigation.navigate('AccountBottom');
    }
    setLoading(false);
  };
  
  return (
    <SafeAreaView style={DefaultTheme.safeAreaContainer}>
      <Header
        title={i18n.t('editPersonalInfo')}
        showBack
      />
      <View style={DefaultTheme.container}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={{ marginVertical: 5 }} >
          <AvatarForm apiCurrentUser ={apiCurrentUser} profileImage={profileImage} onPress={pickImage} previewImage={avatarPreview} />
            <View style={{ marginVertical: 10, paddingHorizontal: 10 }} />
            {showMessage && <InfoText error={isError}> {message}</InfoText>}
            <View style={{ marginVertical: 10, paddingHorizontal: 10 }} />
            <BodyText >{i18n.t('firstName')}</BodyText>
            <TextInput maxLength={25} value={name} onChangeText={(v) => setName(v)} />
            <View style={{ marginVertical: 5, paddingHorizontal: 25 }} />
            <BodyText >{i18n.t('middleName')}</BodyText>
            <TextInput maxLength={25} value={middleName} onChangeText={(v) => setMiddleName(v)} />
            <View style={{ marginVertical: 5, paddingHorizontal: 25 }} />
            <BodyText >{i18n.t('lastName')}</BodyText>
            <TextInput maxLength={25} value={familyName} onChangeText={(v) => setFamilyName(v)} />
            <View style={{ marginVertical: 5 }} />
            <BodyText >{i18n.t('emailAddress')}</BodyText>
            <TextInput keyboardType="email-address" maxLength={25} value={email} onChangeText={(v) => setEmail(v)}/>
            <View style={{ marginVertical: 5 }} />
            <BodyText >{i18n.t('phoneNumber')}</BodyText>
            <TextInput keyboardType="phone-pad" editable={false} maxLength={15} value={phoneNumber}/>
            <View style={{ marginVertical: 5 }} />
            <BodyText >{i18n.t('birthDate')}</BodyText>
            <TextInput maxLength={12} placeholder="dd/mm/yyyy" value={birthDate} onChangeText={(v) => setBirthDate(v)}/>
            <View style={{ marginVertical: 5 }} />
            <BodyText >{i18n.t('website')}</BodyText>
            <TextInput keyboardType="url" maxLength={25} value={website} onChangeText={(v) => setWebsite(v)}/>
            <View style={{ marginVertical: 5 }} />
            <FFAButton isLoading = {loading} title={i18n.t('savePersonalInformation')} onPress={handleOnSave}/>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};



const AvatarForm = ({apiCurrentUser, profileImage, onPress, previewImage }) => {

  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={0.5}
      style={styles.avatarContainer}
    >
      <Image
        source={{
          uri: previewImage
            ? previewImage
            : profileImage
            ? profileImage
            : "https://avatars3.githubusercontent.com/u/44938931?s=460&u=d386991175dac33d37cfef5ada23e67d643469e4&v=4"
        }}
        style={styles.avatar}
      />
      <View style={{ marginVertical: 5 }} />
      <BodyText>Tap to change photo</BodyText>
    </TouchableOpacity>
  );
};

export default EditPersonalInfoScreen;

const styles = StyleSheet.create({
  avatarContainer: {
    backgroundColor: "#F0F0F0",
    alignItems: "center",
    paddingVertical: 25,
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: 80 / 2,
    resizeMode: "cover",
  },
});
