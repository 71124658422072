import React from 'react';
import { View, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import TextInput from './TextInput';
import Colors from '../constants/Colors';



class FFAPasswordField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            icEye: 'eye-off',
            isPassword: true
        }
    }

    getRef = (ref) => {
        if (this.props.getRef)
            this.props.getRef(ref)
    }

    changePwdType = () => {
        const { isPassword } = this.state;
        // set new state value
        this.setState({
            icEye: isPassword ? "eye" : "eye-off",
            isPassword: !isPassword,
        });

    };

    render() {
        const { iconsize, iconcolor, label, style } = this.props;
        const { icEye, isPassword } = this.state;

        return (
            <View style={style}>
                <TextInput
                    {...this.props}
                    secureTextEntry={isPassword}
                    label={label} />
                <MaterialCommunityIcons style={styles.icon}
                    name={icEye}
                    size={iconsize}
                    color={iconcolor}
                    onPress={this.changePwdType}
                />
            </View>
        );
    }
}


const styles = StyleSheet.create({
    container: {
        flex:1,
        
    },
    icon: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    leftIcon: {
        position: 'absolute',
        top: 0,
        left: 0
    }
});

FFAPasswordField.defaultProps = {
    iconsize: 25,
    iconcolor: Colors.iconColor
}


export default FFAPasswordField;