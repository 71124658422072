import React, { useState, useEffect } from "react";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  View,
  ActivityIndicator,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";



import { HeaderText, BodyText } from "../components/Text";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import { useRoute, useNavigation } from "@react-navigation/native";
import FFAButton from "../components/FFAButton";
import { DefaultTheme } from "../theme/DefaultTheme";
import useAddress from "../hooks/useAddress";
import i18n from 'i18n-js';

const ShippingAddressScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { address} = route.params;

  const {editAddress} = useAddress();
  const [houseNo, setHouseNo] = useState(address.houseNo);
  const [addressName, setAddressName] = useState(address.addressName);
  const [street, setStreet] = useState(address.street);
  const [city, setCity] = useState(address.city);
  const [state, setState] = useState(address.state);
  const [zip, setZip] = useState(address.zip);
  const [country, setCountry] = useState(address.country);
  const [isPrimary, setIsPrimary] = useState(address.isPrimary);
  const [loading, setLoading] = useState(false);

  const handleOnSave = async () => {
    setLoading(true);
    await editAddress(addressName, houseNo, street, city, state, zip, country, isPrimary);
    setLoading(false);
    navigation.goBack();
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
        <Header
          title="Shipping address"
          showBack
        />
      <View style={DefaultTheme.container}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={{ marginVertical: 10 }} />
              <View style={{ marginVertical: 5 }} />
              <BodyText>{i18n.t('addressName')}</BodyText>
              <TextInput
                maxLength={25}
                onChangeText={(value) => setAddressName(value)}
                value={addressName}
              />
              <View style={{ marginVertical: 5 }} />
              <BodyText>{i18n.t('houseNo')}</BodyText>
              <TextInput
                maxLength={25}
                onChangeText={(value) => setHouseNo(value)}
                value={houseNo}
              />
              <View style={{ marginVertical: 5 }} />
              <BodyText>{i18n.t('street')}</BodyText>
              <TextInput
                maxLength={15}
                onChangeText={(value) => setStreet(value)}
                value={street}
              />
              <View style={{ marginVertical: 5 }} />
              <BodyText>{i18n.t('city')}</BodyText>
              <TextInput
                multiline={true}
                onChangeText={(value) => setCity(value)}
                value={city}
              />
              <View style={{ marginVertical: 5 }} />
              <BodyText>{i18n.t('zip')}</BodyText>
              <TextInput
                keyboardType="numeric"
                multiline={false}
                onChangeText={(value) => setZip(value)}
                value={zip}
              />
              <View style={{ marginVertical: 5 }} />
              <BodyText>{i18n.t('state')}</BodyText>
              <TextInput
                multiline={false}
                onChangeText={(value) => setState(value)}
                value={state}
              />
              <View style={{ marginVertical: 5 }} />
              <BodyText>{i18n.t('country')}</BodyText>
              <TextInput
                multiline={false}
                onChangeText={(value) => setCountry(value)}
                value={country}
              />
              <View style={{ marginVertical: 5 }} />
              <FFAButton isLoading = {loading} title={i18n.t('saveChanges')} onPress={handleOnSave}/>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

export default ShippingAddressScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    paddingTop: Platform.OS == "ios" ? 20 : StatusBar.currentHeight + 15,
    paddingHorizontal: 25,
  },
});
