import React, { createContext, useContext, useState, useEffect } from "react";
import {Auth} from 'aws-amplify';
import {USERS_API, IMAGE_API, INVENTORY_SMART_API, CONTROLLER_SMART_API, DEVICE_SMART_API} from '../constants/AWSApiUrlConstant';
import { useNavigation } from "@react-navigation/native";
import { AsyncStorage } from "react-native";

const authContext = createContext();

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const [currentUser, setUser] = useState(null);
  const [userToken, setToken] = useState(null);
  const [apiCurrentUser, setApiCurrentUser] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  
  useEffect(() => {
     loadApp();
   }, []);

  const loadApp = async () => {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        setToken(user.signInUserSession.accessToken.jwtToken);
        getCurrentUser(user);
      })
  };

  const callGetAPI = (url, authToken) => {
    fetch(url, {
      method:'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'JWT'+ authToken,
      },
    });
  };

  const callAsyncApisToImprovePerformanceOnLoad = (authToken) => {
    callGetAPI(INVENTORY_SMART_API, authToken);
    callGetAPI(CONTROLLER_SMART_API, authToken);
    callGetAPI(DEVICE_SMART_API, authToken);
  };


  const getCurrentUser = async (user,callback) => {
    try {
      let url = USERS_API.concat(user.username);
      let response = await fetch(url, {
        method:'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'JWT'+ user.signInUserSession.accessToken.jwtToken,
        },
      });
      let json = await response.json();

      setApiCurrentUser(json);
      await AsyncStorage.setItem('language',json.personalData.language);
      setUser(user);
      getProfileImage(user);
      if(typeof callback === 'function'){
        callback();
      }
      callAsyncApisToImprovePerformanceOnLoad(user.signInUserSession.accessToken.jwtToken);
    } catch (err) {
      signOut();
    }
  };

  const getProfileImage = async(user) => {

    var myHeaders = new Headers();
    myHeaders.append("accept", "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", 'JWT'+ user.signInUserSession.accessToken.jwtToken);


    const config = {
        method: 'get',
        headers: myHeaders,
      }
    
    let url = IMAGE_API.concat('?username=').concat(user.username);
    fetch(url, config)
      .then(response => response.text())
      .then(result => {setProfileImage(result);})
  }

  const editProfile = async(username, name, middleName, familyName, email, website, birthDate, phoneNumber) => {
    let url = USERS_API;
    fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'JWT'+ userToken,
      },
      body: JSON.stringify(
        {
          "username": username,
          "accountData": {
            "email":email,
            "phoneNo":phoneNumber
           },
          "personalData": {
            "name": name,
            "middleName": middleName,
            "familyName": familyName,
            "website": website,
            "birthdate":birthDate
          }
        }
      )
    }).then( () => {
      setApiCurrentUser({...apiCurrentUser, 
        personalData: {
          "name": name,
          "middleName": middleName,
          "familyName": familyName,
          "website": website,
          "birthdate":birthDate,
      }, accountData:{
          "email":email,
          "phoneNo":phoneNumber
      } });
    }
    );
  };

  const updateLanguage = async(username, language) => {
    let url = USERS_API;
    fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'JWT'+ userToken,
      },
      body: JSON.stringify(
        {
          "username": username,
          "personalData": {
            "language": language
          }
        }
      )
    }).then( () => {
      setApiCurrentUser({...apiCurrentUser, 
        personalData: {
          language:language
      }});
    }
    );
  };

  const uploadImage = (image) => {
    
    var strImage = image.replace(/^data:image\/[a-z]+;base64,/, "");
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "text/plain");
    myHeaders.append("Authorization", 'JWT'+ userToken);


    const config = {
        method: 'post',
        headers: myHeaders,
        body: strImage
      }
    
    let url = IMAGE_API.concat('?username=').concat(apiCurrentUser.username);
    fetch(url, config)
      .then(response => response.text())
      .then(result => {
        getProfileImage(currentUser);
      })

  }

  const signOut = async (callback) => {
    await Auth.signOut();
    await setUser(null);
    await setToken(null);
    await setApiCurrentUser(null);
    if(typeof callback === 'function'){
      callback();
    }
  };


  const signIn = async(username, password, successCallBack, errorCallBack) => {
    await Auth.signIn(username, password)
    .then(user => {
      setToken(user.signInUserSession.accessToken.jwtToken);
      getCurrentUser(user, successCallBack);
    })
    .catch(err => {
      if(typeof errorCallBack === 'function') {
        errorCallBack(err);
      }
    })
  }

  const forgotPassword = async (username, callback, errorCallBack) => {
    await Auth.forgotPassword(username)
    .then(data => {
      if(typeof callback === 'function'){
          callback();
      }
    })
    .catch(err => {
      if(typeof errorCallBack === 'function'){
        errorCallBack(err);
      }
    })
  }

  // Upon confirmation redirect the user to the Sign In page
  const forgotPasswordSubmit = async(username, authCode, newPassword) => {
    const code = authCode;
    await Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  const changePassword = async (username,oldPassword, newPassword, callback) => {

    await Auth.currentAuthenticatedUser()
    .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
    })
    .then(data => {
      if(typeof callback === 'function'){
        callback();
      }
    })

  }

  
  return {
    currentUser,
    apiCurrentUser,
    profileImage,
    userToken,
    isLoading,
    signOut,
    signIn,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    editProfile,
    uploadImage,
    updateLanguage
  };
};
