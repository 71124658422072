import { useNavigation } from "@react-navigation/native";
import React, { useState, useEffect, useRef } from "react";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  View,
  FlatList,
  TouchableOpacity,
  Image,
  Text,
  Modal,
  ActivityIndicator,
  Alert,
} from "react-native";

import Header from "../../components/Header";
import useLocations from "../../hooks/useLocations";
import { ListItem } from "react-native-elements";
import Colors from "../../constants/Colors";
import { LocationIcon } from "../../components/Icons";
import { useIsFocused } from "@react-navigation/native";
import DeviceNumberRegistration from "../registration/DeviceNumberRegistration";
import { ScrollView } from "react-native-gesture-handler";
import i18n from 'i18n-js';
import { DefaultTheme } from "../../theme/DefaultTheme";


function useIsMountedRef(){
  const isMountedRef = useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => isMountedRef.current = false;
  });
  return isMountedRef;
}


const LocationScreen = () => {
  const [isLoading, setLoading] = useState(false);
  const {locations, loadLocations, addLocation, deleteLocation} = useLocations();
  const [isTextFieldVisible, setTextFieldVisible] = useState(false);
  const [title, setTitle] = useState(i18n.t('location'));

  const isFocused = useIsFocused();
  const isMountedRef = useIsMountedRef();
  
  useEffect(() => {

      setLoading(true);
      const getData = async () => {
      try {
        if(isMountedRef.current){
          await loadLocations() ;
        }
        setLoading(false);
      } catch (e) {
        console.info(e);
      }
    };
  getData();
  
    
  }, [isFocused, isMountedRef]);
  
  const handleLocation = async (locationText) => {
    setLoading(true);
    await addLocation(locationText);
    await loadLocations();
    setTextFieldVisible(false);
    setTitle(i18n.t('location'))
    setLoading(false);
  }

  const handleDeleteLocation = async (locationText) => {
    setLoading(true);
    await deleteLocation(locationText);
    await loadLocations();
    setLoading(false);
  }

  const handleOnAdd = () => {
    setTitle(!isTextFieldVisible ? i18n.t('addLocation') : i18n.t('location'))
    setTextFieldVisible(!isTextFieldVisible);
  };
  

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
        <Header
          showMenu
          showLogo
          title={title}
          showAdd
          onAdd={handleOnAdd}
          showNotification
        />
      <View style={DefaultTheme.container}>
        
        <ScrollView>
        <View style={{ marginVertical: 5 }} />
        {isTextFieldVisible && <DeviceNumberRegistration placeholder="Enter Location name" onPress={handleLocation} />}
        {isLoading ? 
          (<ActivityIndicator size="large" color={Colors.appColor}  />) 
          :
          (
            <> 
            {
              locations && 
            <LocationList locations={locations} handleDeleteLocation={handleDeleteLocation} />
            }
            </>
          )
        }
        </ScrollView>
      </View>
      
    </SafeAreaView>
  );
};

const LocationList = ({locations, handleDeleteLocation}) => {
  const { navigate } = useNavigation();
  const [loading, setLoading] = useState(false);

  const loadController = (location) => {
    setLoading(true);
    navigate("controller", { locationName: location.name });
  };

  
  return (

    <View>
      {locations.map((item, i) => (
        <ListItem
          pad={16}
          key={i}
          title={item.name}
          leftIcon={
            <LocationIcon  focused={true} />
          }
          badge={{
            value: item.count,
            status: "success",
            textStyle: { color: "#FFF" },
            containerStyle: {},
            badgeStyle:{backgroundColor:Colors.appColor},
            // onPress: () => {
            //   alert(item.activeController);
            // },
          }}
          rightIcon={{
            name:'trash-can-outline',
            type:'material-community',
            color:Colors.deleteIconColor,
            onPress: () => handleDeleteLocation(item.name)
          }}
          bottomDivider

          chevron={{ color: Colors.appColor }}
          onPress={() => loadController(item)}
        />
      ))}
    </View>
  );
};
export default LocationScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    // paddingTop: Platform.OS == "ios" ? 20 : StatusBar.currentHeight + 15,
    paddingHorizontal: 10,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  badgeContainer: {},
});
