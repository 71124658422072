import { useRoute } from "@react-navigation/native";
import React from "react";
import {
  SafeAreaView,
  View,
} from "react-native";

import Header from "../../components/Header";
import DeviceNumberRegistration from './DeviceNumberRegistration';
import { DefaultTheme } from '../../theme/DefaultTheme';
import QRCodeRegistration from "./QRCodeRegistration";
import { isWeb } from "../../constants/common";


const RegistrationScreen = (props) => {
    const route = useRoute();
    const {handleRegistration, placeholder, title} = route.params;
    
    return(
        <SafeAreaView style={DefaultTheme.safeAreaContainer}>
            <Header showBack title={title}/>
             <View style={DefaultTheme.container}>
                <View style={{ marginVertical: 5 }} />
                <DeviceNumberRegistration placeholder={placeholder} onPress={handleRegistration && handleRegistration}/>
                <View style={{ marginVertical: 5 }} />
                {isWeb ? null : <QRCodeRegistration onPress={handleRegistration && handleRegistration}/>}
           </View>
        </SafeAreaView>
    );
}

export default RegistrationScreen;




  


