import React, { useState } from 'react'; 
import { SafeAreaView, View, StyleSheet, Alert } from 'react-native';
import Header from '../../components/Header';

import i18n from 'i18n-js';
import { DefaultTheme } from '../../theme/DefaultTheme';
import { ListItem } from 'react-native-elements';
import { ScrollView } from 'react-native-gesture-handler';
import { P } from '../../components/typography';
import FFAButton from '../../components/FFAButton';
import { useNavigation } from '@react-navigation/native';

const SchedularScreen = () => {
  const {navigate} = useNavigation();
  const [title] = useState(i18n.t("schedule"));

  const [isEnabled, setIsEnabled] = useState(false);
  const toggleSwitch = () => setIsEnabled(previousState => !previousState);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
      <Header
        showBack
        title={title}
        showNotification
      />
      <View style={DefaultTheme.container}>
      <ScrollView>
          <View>
            <ListItem
              key="1"
              title="PM 6:30"
              subtitle={
                <View>
                  <P>EveryDay</P>
                  <P>Duration : 30 min</P>
                </View>}
              switch = {{
                trackColor : {false: "#767577", true: "#81b0ff"} ,
                thumbColor:isEnabled ? "#f5dd4b" : "#f4f3f4",
                ios_backgroundColor:"#3e3e3e",
                onValueChange:toggleSwitch,
                value:isEnabled
              }}
              bottomDivider
            />
          </View>
        </ScrollView>
        <View style={styles.bottomComponent}>
          <FFAButton title={i18n.t("addSchedule")} onPress={() =>  navigate('addSchedular')}/>
        </View>
      </View>
    </SafeAreaView>
  );
}

export default SchedularScreen;

const styles = StyleSheet.create({
  bottomComponent: {
      marginBottom:5
  }
 
});