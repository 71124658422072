import React, { useEffect, useState } from 'react';
import useLocations from "../../hooks/useLocations";
import useControllers from "../../hooks/useControllers";
import useDataCollectors from "../../hooks/useDataCollectors";
import Dropdown from '../../components/Dropdown';
import Button from "../../components/Button";
import FlexContainer from '../../components/FlexContainer';
import i18n from 'i18n-js';
import { useRoute } from "@react-navigation/native";
import Calender from '../../components/Calender';
import Constants from './constants';
const UserDashboardFilter = ({
    onFilterChange,
    onFilterReady,
    canExpandCollapseHistory,
    detailView,
    toggleHistoricalDetailView,
    graphView,
    toggleGraphView
}) => {
    const { locations, locationsLoaded } = useLocations();
    const { loadControllers } = useControllers();
    const { loadCollectors } = useDataCollectors();
    const [canEdit, setFilterEditability] = useState(false);
    const [location, setLocation] = useState();
    const [controller, setController] = useState();
    const [collector, setCollector] = useState();
    const [controllers, setControllers] = useState('');
    const [collectors, setCollectors] = useState('');
    const [filterReset, resetFilter] = useState(false);
    const [controllersReady, setControllersReady] = useState(true);
    const [collectorsReady, setCollectorsReady] = useState(true);
    const route = useRoute();
    const dataType = route.params && route.params.dataType;
    const isHistorical = route.params && route.params.viewType === 'Historical';
    const currentDate = new Date();
    const defaultFromDate = new Date();
    defaultFromDate.setDate(currentDate.getDate() - (Constants.lastNDaysHistoricalData - 1));
    const [fromDate, setFromDate] = useState(isHistorical ? new Date(defaultFromDate) : null);
    const [toDate, setToDate] = useState(isHistorical ? currentDate : null);

    const onApplyFilter = () => {
        if (onFilterChange) {
            onFilterChange({
                location,
                controller,
                collector,
                fromDate,
                toDate,
                dataType: dataType || 'all'
            });
        }
    };
    const onResetFilter = () => {
        setLocation('');
        setController('');
        setCollector('');
        resetFilter(true);
    };

    const onLocationChange = (selectedLocation, onLoad) => {
        setLocation(selectedLocation);
        if (!onLoad) {
            setController('');
            setCollector('');
        }
    };
    const onControllerChange = (selectedController, onLoad) => {
        setController(selectedController);
        if (!onLoad) {
            setCollector('');
        }
    };
    const onCollectorChange = (selectedCollector) => {
        setCollector(selectedCollector);
    };
    
    useEffect(() => {
        if (typeof onFilterReady === 'function'){
            onFilterReady(locationsLoaded);
        }
        if (locationsLoaded && locations && locations.length > 0) {
            onLocationChange(locations[0].name);
        }
    }, [locations, locationsLoaded]);
    
    useEffect(() => {
        if (controllers && controllers.length > 0) {
            onControllerChange(controllers[0].deviceId, true);
        }
    }, [controllers]);

    useEffect(() => {
        if (collectors && collectors.length === 1) {
            onCollectorChange(collectors[0].deviceId, true);
        }
    }, [collectors]);
    
    useEffect(() => {
        if (location) {
            resetFilter(false);
            setControllersReady(false);
            loadControllers(location, (response) => {
                setControllersReady(true);
                setControllers(response.controllers);
            });
        }
    }, [location]);
    
    useEffect(() => {
        if (controller) {
            resetFilter(false);
            onApplyFilter();
            setCollectorsReady(false);
            loadCollectors(location, controller, (response) => {
                setCollectorsReady(true);
                setCollectors(response.collectors);
            });
        }
    }, [controller]);

    useEffect(() => {
        if (collector) {
            resetFilter(false);
            onApplyFilter();
        }
    }, [collector]);

    useEffect(() => {
        if (fromDate) {
            resetFilter(false);
            onApplyFilter();
        }
    }, [fromDate]);

    useEffect(() => {
        if (toDate) {
            resetFilter(false);
            onApplyFilter();
        }
    }, [toDate]);

    const dataTypes = [{
        key: 'all',
        name: i18n.t('all')
    }, {
        key: 'soilMoisture',
        name: i18n.t('soilMoisture')
    }, {
        key: 'ph',
        name: i18n.t('ph')
    }, {
        key: 'temperature',
        name: i18n.t('temperature')
    }, {
        key: 'humidity',
        name: i18n.t('humidity')
    }, {
        key: 'sunlight',
        name: i18n.t('sunlight')
    }, {
        key: 'rssi',
        name: i18n.t('rssi')
    }, {
        key: 'snr',
        name: i18n.t('snr')
    }];

    const expandCollapseHistoryButton = isHistorical && !dataType &&
        canExpandCollapseHistory ?
        <Button
            title={''}
            onPress={() => toggleHistoricalDetailView(!detailView)}
            primary
            btnStyle={{ paddingRight: 5, paddingLeft: 5, marginLeft: 10, borderRadius: 20 }}
            icon={detailView ? require(`../../assets/images/app-images/collapsebutton.png`) :
                require(`../../assets/images/app-images/expandbutton.png`)}
            iconEnd
        /> : null;
    const toggleGraphViewButton = isHistorical &&
        (!dataType || Constants.sensorDataMap[dataType].showInGraph) &&
        canExpandCollapseHistory ?
        <Button
            title={''}
            onPress={() => toggleGraphView(!graphView)}
            btnStyle={{ paddingRight: 5, paddingLeft: 5, marginLeft: 10, borderRadius: 20,
                backgroundColor: graphView ? '#F7D57B' : '#00a859'}}
            icon={graphView ? require(`../../assets/images/app-images/trend_disabled.png`) :
                require(`../../assets/images/app-images/trend.png`)}
            iconEnd
        /> : null;
    return (
        <FlexContainer margin={5} paddingTop={10} paddingBottom={10}>
            <Dropdown bold
                valueFontSize={13}                
                items={dataTypes}
                isVisible={true}
                itemKey='key'
                displayValueKey='name'
                label={i18n.t('dataType')}
                isEditable={false}
                selectedKey={dataType || 'all'}/>
            <Dropdown bold
                isReady={locationsLoaded}
                isAsync={true}
                valueFontSize={13}                
                items={locationsLoaded && locations.length < 1 ? [{
                    name: i18n.t('noLocations')
                }] : locations}
                isVisible={true}
                onValueChange={onLocationChange}
                itemKey='name'
                displayValueKey='name'
                label={i18n.t('location')}
                isEditable={canEdit && locations.length > 0}
                defaultOptionLabel={i18n.t('selectLocation')}
                selectedKey={locationsLoaded && locations.length < 1 ? i18n.t('noLocations') : location}/>
            <Dropdown bold
                isReady={controllersReady}
                isAsync={true}
                valueFontSize={13}                
                isVisible={true}
                isDisabled = {!location}
                items={controllersReady && controllers.length < 1 ? [{
                    deviceId: i18n.t('noControllers')
                }] : controllers}
                itemKey='deviceId'
                displayValueKey='deviceId'
                label={i18n.t('controller')}
                isEditable={canEdit && controllers.length > 0}
                selectedKey={location ? controller : i18n.t('noControllers')}
                onValueChange={onControllerChange}/>
            <Dropdown bold
                isReady={collectorsReady}
                isAsync={true}
                valueFontSize={13}                
                isVisible={true}
                isDisabled = {!location || !controller}
                items={collectorsReady && collectors.length < 1 ? [{
                    deviceId: i18n.t('noCollectors')
                }] : collectors}
                itemKey='deviceId'
                displayValueKey='deviceId'
                label={i18n.t('dataCollector')}
                isEditable={canEdit && collectors.length > 0}
                selectedKey={location && controller ? collector : i18n.t('noCollectors')}
                onValueChange={onCollectorChange}/>
            <Calender bold
                valueFontSize={13}                
                isVisible={isHistorical}
                label={i18n.t('fromDate')}
                isEditable={canEdit}
                id={'fromDatePicker'}
                onValueChange={(date) => setFromDate(date)}
                selectedDate={isHistorical ? fromDate.toLocaleDateString() : ''}
                maxDate={currentDate}
            />
            <Calender bold
                valueFontSize={13}                
                isVisible={isHistorical}
                label={i18n.t('toDate')}
                isEditable={canEdit}
                id={'toDatePicker'}
                onValueChange={(date) => setToDate(date)}
                selectedDate={isHistorical ? toDate.toLocaleDateString() : ''}
                minDate={fromDate}
                maxDate={currentDate}
            />
            { canEdit === false ? <FlexContainer direction={'row'} justifyContent={'flex-start'} marginTop={10}>
                <Button
                    title={i18n.t('editFilter')}
                    onPress={() => setFilterEditability(!canEdit)}
                    primary
                    btnStyle={{ paddingRight: 5, borderRadius: 0 }}
                    icon={require("../../assets/images/search-3-32.png")}
                    iconEnd
                />
                { expandCollapseHistoryButton }
                { toggleGraphViewButton }
            </FlexContainer> : <FlexContainer direction={'row'} justifyContent={'flex-start'} marginTop={10}>
                    { canEdit ? <Button
                        title={i18n.t('hideFilter')}
                        onPress={() => setFilterEditability(false)}
                        btnStyle={{ borderRadius: 0 }}
                        secondary
                        iconEnd
                        icon={require("../../assets/images/search-3-32.png")}
                    /> : null }
                    { filterReset !== true ? <Button
                        title={i18n.t('resetFilter')}
                        onPress={() => onResetFilter()}
                        btnStyle={{ marginLeft: 10, borderRadius: 0 }}
                        secondary
                        iconEnd
                        icon={require("../../assets/images/trash-9-32.png")}
                    /> : null }
                    { expandCollapseHistoryButton }
                    { toggleGraphViewButton }
                </FlexContainer> }
        </FlexContainer>
    );
};

export default UserDashboardFilter;
