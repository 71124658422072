import React, { useEffect, useState } from "react";
import { StyleSheet, View, ActivityIndicator } from "react-native";

import { useAuth } from "../hooks/useAuth";
import { useNavigation } from "@react-navigation/native";
import { useIsFocused } from "@react-navigation/native";
import Colors from "../constants/Colors";
import Auth from "@aws-amplify/auth";
import { AsyncStorage } from "react-native";
import i18n from 'i18n-js';

i18n.translations = {
  'hn': require('../translations/hn'),
  'en': require('../translations/en'),
  'mr': require('../translations/mr'),
  'bn': require('../translations/bn'),
  'pa': require('../translations/pa'),
  'te': require('../translations/te')
};
i18n.fallbacks = true;



const AuthLoadingScreen = () => {
  const { userToken, currentUser } = useAuth();
  const { navigate } = useNavigation();
  const isFocused = useIsFocused();
  const [token, setToken] = useState();
  const [language, setLanguage] = useState('en');



  useEffect(() => {

    const getData = async () => {
      try {
        const value = await AsyncStorage.getItem('language');
        if (value !== null) {
          setLanguage(value);
        } else{
          await AsyncStorage.setItem(
            'language',language
          );
        }
        i18n.locale = language;
        await loadApp();
        navigate(token ? "app" : "auth");
      } catch (e) {
        console.info(e);
      }
    };
    getData();
  }, [isFocused]);

  const loadApp = async () => {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        setToken(user.signInUserSession.accessToken.jwtToken);
      })
      .catch((err) => console.log(err));
      
  };

  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color={Colors.activityColor} />
    </View>
  );
};
export default AuthLoadingScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fffffF",
    alignItems: "center",
    justifyContent: "center",
  },
});
