import React from 'react';
import { useDashboard } from '../../hooks/useDashboard';
import OperationalComponent from './OperationalComponent';
import dashboardComponentFactory from './dashboard-component-factory';

const Dashboard = () => {
    const {
        isLoading,
        userType
    } = useDashboard();
    const Component = dashboardComponentFactory.getComponent(userType);
    return (
        <React.Fragment>
            <OperationalComponent isLoading={isLoading}
                component={Component} withoutData={true} />
        </React.Fragment>
    );
};

export default Dashboard;
