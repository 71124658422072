import { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "./useAuth";
import { LOCATION_RETRIEVE_API,LOCATION_REGISTER_API, LOCATION_DEREGISTER_API } from "../constants/AWSApiUrlConstant";


const useLocations = () => {

  const {userToken, apiCurrentUser}  = useAuth();
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationsLoaded, setLocationsLoaded] = useState(false);

  useEffect(() => {
    if (apiCurrentUser) {
      loadLocations();
    }
  }, [apiCurrentUser]);
  
  const loadLocations = async () => {
    setLocationsLoaded(false);
    let LOCATION_API = LOCATION_RETRIEVE_API.concat(apiCurrentUser.username);
    let response = await fetch(LOCATION_API, {
        method:'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'JWT'+ userToken,
        }
      });

      let json = await response.json();
      setLocations(json.locations);
      setLocationsLoaded(true);
  };

  
  const addLocation = async (location) => {
    let LOCATION_REG_API = LOCATION_REGISTER_API.concat(apiCurrentUser.username).concat('/').concat(location);
    let response = await fetch(LOCATION_REG_API, {
        method:'post',
        headers: {
          Accept: 'application/json',
          // Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8
          'Content-Type': 'application/json',
          Authorization: 'JWT'+ userToken,
        }
      });
  }

  const deleteLocation = async (location) => {
    let LOCATION_DEREG_API = LOCATION_DEREGISTER_API.concat(apiCurrentUser.username).concat('/').concat(location);
    let response = await fetch(LOCATION_DEREG_API, {
        method:'post',
        headers: {
          Accept: 'application/json',
          // Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8
          'Content-Type': 'application/json',
          Authorization: 'JWT'+ userToken,
        }
      });
  }

  const setCurrentLocation = async (location) => {
    setSelectedLocation(location);
  };


  return { selectedLocation, locations,loadLocations, addLocation, deleteLocation, locationsLoaded};
};

export default useLocations;