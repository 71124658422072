import React, { useState, useEffect } from 'react';
import FlexContainer from '../../components/FlexContainer';
import Constants from './constants';
import Colors from "../../constants/Colors";
import i18n from 'i18n-js';
import ReadOnlyValue from '../../components/ReadOnlyValue';
import ReadOnlyValueWithImage from '../../components/ReadOnlyValueWithImage';
import { isDesktop } from "../../constants/common";
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { useRoute } from "@react-navigation/native";
import { TouchableOpacity } from "react-native";
const getFormattedDateTime = (date) => {
    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    let datePart = date.getDate();
    datePart = datePart < 10 ? `0${datePart}` : datePart;
    return `${datePart}/${month}/${date.getFullYear()} ${date.toLocaleTimeString()}`;
};
const DataComponent = ({ dataType, dataKey, value, gradientColors, imageSrc, isHistorical }) => {
    return (
        <React.Fragment>
            { !dataType || dataType === dataKey ?
            <FlexContainer
                flex={1}
                justifyContent={'center'}
                gradientColors={gradientColors}
                marginLeft={isDesktop ? 10 : 5}
                marginRight={isDesktop ? 0 : -5}
                marginBottom={isDesktop ? 0 : 10}>
                <ReadOnlyValueWithImage
                    imageSrc={imageSrc}
                    noTopMargin={true}
                    boldValue={false}
                    label={i18n.t(dataKey)}
                    value={`${value || ''}`}
                    labelColor={isHistorical ? Constants.greyColor : Constants.textColor}
                    labelSize={isHistorical ? Constants.cardLabelFontSize : null}
                    valueColor={isHistorical ? Constants.blackColor : Constants.textColorMap[dataKey]}
                    valueSize={Constants.cardValueFontSize}/>
            </FlexContainer> : null }
        </React.Fragment>
    )
}
const SensorDetailData = ({
    creationDate,
    soilMoisture,
    ph,
    humidity,
    temperature,
    sunlight,
    rssi,
    snr,
    dataType,
    canViewSM,
    isEvenRow,
    showDetailedView
}) => {
    const route = useRoute();
    const isHistorical = route.params && route.params.viewType === 'Historical';
    const [showDetail, toggleShowDetail] = useState(!isHistorical);
    const serverDate = new Date(creationDate);
    const date = getFormattedDateTime(serverDate);
    const dataMapToShow = isDesktop ?
        ( isHistorical ? [
            [
                Constants.sensorDataMap.temperature,
                Constants.sensorDataMap.humidity,
                Constants.sensorDataMap.sunlight,
                Constants.sensorDataMap.rssi,
                Constants.sensorDataMap.snr
            ]
        ] :
        [
        [
            Constants.sensorDataMap.ph, Constants.sensorDataMap.temperature
        ], [
            Constants.sensorDataMap.humidity, Constants.sensorDataMap.sunlight
        ], [
            Constants.sensorDataMap.rssi, Constants.sensorDataMap.snr
        ]
        ]) :
        ( isHistorical ?
            [
                [
                    Constants.sensorDataMap.ph, Constants.sensorDataMap.temperature
                ],
                [
                    Constants.sensorDataMap.humidity, Constants.sensorDataMap.sunlight
                ],
                [
                    Constants.sensorDataMap.rssi, Constants.sensorDataMap.snr
                ]
            ] :
            [
            [
                Constants.sensorDataMap.ph, Constants.sensorDataMap.temperature, Constants.sensorDataMap.humidity
            ],
            [
                Constants.sensorDataMap.sunlight, Constants.sensorDataMap.rssi, Constants.sensorDataMap.snr
            ]
        ]);
    const valueMap = {
        ph,
        temperature,
        humidity,
        sunlight,
        rssi,
        snr,
        soilMoisture
    };
    if (isHistorical && dataType === Constants.sensorDataMap.snr.key) {
        dataMapToShow[dataMapToShow.length - 1].push(Constants.sensorDataMap.snr);
    };
    const gradientColors = !isHistorical ? (isEvenRow ? Constants.gradientColors : Constants.alternateGradientColors) :
        Constants.whiteGradientColors;
    const textColor = isHistorical ? Constants.blackColor : Constants.textColor;
    useEffect(() => {
        if (isHistorical) {
            toggleShowDetail(showDetailedView);
        }
    }, [showDetailedView]);
    return (
        <FlexContainer direction={'column'}
            marginTop={10}
            borderTopWidth={1}
            borderBottomWidth={showDetail && !dataType ? 1 : 0}
            borderLeftWidth={1}
            borderRightWidth={1}
            borderColor={isHistorical ? Colors.appColor : 'transparent'}>
            <FlexContainer flex={1} alignItems={'center'}
                direction={'row'}
                justifyContent={isDesktop ? 'space-around' : 'space-around'}
                gradientColors={gradientColors}
                borderBottomWidth={1}
                borderColor={isHistorical ? Colors.appColor : 'transparent'}>
                <ReadOnlyValue direction={'row'} value={date}
                    labelColor={textColor}
                    valueColor={textColor}/>
                { isHistorical ? <ReadOnlyValueWithImage direction={'row'}
                    marginLeft={5}
                    label={isDesktop ? i18n.t(dataType || 'soilMoisture') : ''}
                    imageSrc={Constants.sensorDataMap[dataType || 'soilMoisture'].imageSrc}
                    value={Constants.sensorDataMap[dataType || 'soilMoisture'].value(valueMap[dataType || 'soilMoisture'])}
                    labelColor={textColor}
                    valueColor={textColor}/> : null }
                { isHistorical && isDesktop && !dataType  ? <ReadOnlyValueWithImage direction={'row'}
                    marginLeft={5}
                    label={isDesktop ? i18n.t('ph') : ''}
                    imageSrc={Constants.sensorDataMap[dataType || 'ph'].imageSrc}
                    value={Constants.sensorDataMap[dataType || 'ph'].value(valueMap[dataType || 'ph'])}
                    labelColor={textColor}
                    valueColor={textColor}/> : null }
                { isHistorical && !dataType ?
                    <TouchableOpacity onPress={() => toggleShowDetail(!showDetail)}>
                    <ReadOnlyValueWithImage direction={'row'}
                        marginLeft={5}
                        imageSrc={showDetail ? Constants.collapseImage: Constants.expandImage}/>
                </TouchableOpacity> : null }
            </FlexContainer>
            { showDetail && !dataType ? <FlexContainer padding={0}
                marginRight={isHistorical ? 10 : 0}
                marginBottom={10}
                marginTop={10}>
                {!isHistorical && (!dataType || dataType === 'soilMoisture') ? <FlexContainer flex={1}
                    paddingBottom={15}
                    justifyContent={'flex-start'}
                    direction={'column'}
                    gradientColors={gradientColors}
                    alignItems={'center'}>
                    <ReadOnlyValue label={i18n.t('soilMoisture')} value={canViewSM ? '' : soilMoisture}
                        labelColor={textColor} valueColor={textColor}/>
                    { canViewSM ? <AnimatedCircularProgress
                            rotation={360}
                            size={120}
                            width={15}
                            fill={soilMoisture}
                            tintColor={'#00ff00'}
                            backgroundColor={Colors.tintColor}>
                        {
                            (fill) => (
                            <ReadOnlyValue label={`${soilMoisture}%`}
                                labelColor={Constants.textColorMap.soilMoisture}
                                labelSize={isDesktop ? 26 : Constants.cardValueFontSize}
                                noBoldLabel={true} />
                            )
                        }
                        </AnimatedCircularProgress> : null}
                </FlexContainer> : null}
                <FlexContainer flex={1} direction={'column'} justifyContent={isDesktop ? 'flex-start' : null}>
                    {
                        dataMapToShow.map((row, index) =>
                        <FlexContainer
                            flex={isDesktop ? null : 1}
                            direction={isDesktop ? null : 'row'}
                            marginBottom={index < dataMapToShow.length -1 && isDesktop  ? 10 : 0}
                            marginTop={index === 0 && !isDesktop ? 10 : 0} key={index}>
                            {
                                row.map((col) =>
                                <DataComponent dataType={dataType} dataKey={col.key}
                                    imageSrc = {isHistorical ? col.imageSrc : null}
                                    gradientColors={gradientColors}
                                    isHistorical={isHistorical}
                                    value={col.value(valueMap[col.key])} key={col.key}/>)
                            }
                        </FlexContainer>)
                    }
                </FlexContainer>
            </FlexContainer> : null }
        </FlexContainer>
    );
};

export default SensorDetailData;