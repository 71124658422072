import React from 'react';
import { VictoryChart, VictoryArea, VictoryGroup, VictoryTooltip, VictoryScatter } from '../../Victory';
const AreaGraph = (props) => {
    const {
        minValue,
        maxValue,
        yValueKey,
        data,
        graphColor,
        showTooltip
    } = props;
    return (
        <VictoryChart minDomain={{
            y: minValue
        }}
        maxDomain={{
            y: maxValue
        }}>
            <VictoryGroup
            y={yValueKey}
            labels={({ datum }) => `${datum[yValueKey]}`}
            labelComponent={
                <VictoryTooltip
                  style={{ fontSize: 16 }}
                />
              }
              style={
                {
                    data: {
                        stroke: graphColor,
                        strokeWidth: 1,
                        strokeLinecap: "round"
                    },
                    labels: {
                        fill: graphColor
                    }
                }
            }
            data={data}>
                  <VictoryArea style={
                {
                    data: {
                        stroke: graphColor,
                        fill: graphColor,
                        strokeWidth: 1,
                        strokeLinecap: "round"
                    },
                    labels: {
                        fill: graphColor
                    }
                }
            }/>
                {showTooltip ? <VictoryScatter
                size={({ active }) => active ? 8 : 3}
                /> : null }
            </VictoryGroup>
        </VictoryChart>
    );
};

export default AreaGraph;