import React, { useEffect, useState } from 'react';
import { StyleSheet, ActivityIndicator } from "react-native";
import { BodyText } from './Text';
import {
    Picker
} from '@react-native-community/picker';
import Colors from "../constants/Colors";
import i18n from 'i18n-js';
import FlexContainer from './FlexContainer';
import { isDesktop } from "../constants/common";

const Dropdown = ({
    label,
    selectedKey,
    items,
    itemKey,
    displayValueKey,
    onValueChange,
    isEditable,
    defaultOptionLabel,
    isVisible,
    isDisabled,
    bold,
    valueFontSize,
    valueFontColor,
    isReady,
    isAsync
}) => {
    const [selectedValue, setSelectedValue] = useState('');
    useEffect(() => {
        const matchedItem = (items || []).find(item => item[itemKey || 'key'] === selectedKey);
        setSelectedValue(matchedItem ? matchedItem[displayValueKey || 'value'] : '');
    }, [selectedKey, items]);
    if (!isVisible) {
        return null;
    }
    const showSideBySide = !isDesktop;
    return (
        <FlexContainer direction={showSideBySide ? 'row' : 'column'}>
            <BodyText bold={bold} style={[
                showSideBySide ? styles.label : {}
            ]}>{label}</BodyText>
            { isEditable && (!isAsync || isReady) ? <Picker
                enabled={!isDisabled}
                style={[
                    styles.dropdown,
                    {
                        marginLeft: showSideBySide ? 5 : 0
                    }
                ]}
                selectedValue={selectedKey}
                onValueChange={(itemValue) => onValueChange && onValueChange(itemValue)}>
                    <Picker.Item
                    label={defaultOptionLabel || i18n.t('pleaseSelect')} key={-1}
                    value='' />
                {(items || []).map(item => <Picker.Item
                    key={item[itemKey || 'key']}
                    label={item[displayValueKey || 'value']}
                    value={item[itemKey || 'key']} />)}
            </Picker> : ((!isAsync ||  isReady) ? <BodyText
                size={valueFontSize}
                style={
                    {
                        marginLeft: showSideBySide ? 5 : 0
                    },
                    showSideBySide ? styles.label : {}
                }
                color={valueFontColor}>{selectedValue}</BodyText> :
                isAsync ? <ActivityIndicator size="large" color={Colors.appColor}
                style={[
                    styles.busyIndicator,
                    {
                        marginLeft: showSideBySide ? 5 : 0
                    }
                ]}/> : null)}
        </FlexContainer>
    );
};

export default Dropdown;

const styles = StyleSheet.create({
    label: {
        marginTop: 15,
        minWidth: 100,
        paddingTop: 5,
        paddingBottom: 5,
        color: Colors.appColor,
        fontFamily: "playfair-semibold",
    },
    dropdown: {
        marginTop: 5,
        borderColor: 'black',
        borderWidth: 1,
        borderStyle: 'solid',
        minWidth: 180,
        borderRadius: 5,
        paddingTop: 5,
        paddingRight: 10,
        paddingBottom: 5,
        paddingLeft: 10,
        color: Colors.appColor,
        fontFamily: "playfair-regular",
    },
    busyIndicator: {
        minWidth: 180,
        color: Colors.appColor,
        fontFamily: "playfair-regular",
    }
});