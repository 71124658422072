import {useState, useEffect } from "react";
import { useAuth } from "./useAuth";
import { LOCATION_RETRIEVE_API, LOCATION_REGISTER_API, LOCATION_DEREGISTER_API } from "../constants/AWSApiUrlConstant";



const useDataCollectors = () => {
  
  
  const {userToken, apiCurrentUser}  = useAuth();
  const [collectors, setCollectors] = useState([]); 
  const [controller, setController] = useState(); 
  let COLLECTOR_RETRIEVE_API = LOCATION_RETRIEVE_API.concat(apiCurrentUser.username).concat('/');
  let COLLECTOR_REGISTER_PATH = LOCATION_REGISTER_API.concat(apiCurrentUser.username).concat('/');
  let COLLECTOR_DEREGISTER_PATH = LOCATION_DEREGISTER_API.concat(apiCurrentUser.username).concat('/');


  // useEffect(() => {
  //   loadControllers(locationName);
  // }, []);

  const loadCollectors = async (location, deviceId, callback) => {

    let CONTROLLER_API = COLLECTOR_RETRIEVE_API.concat(location).concat('/').concat(deviceId);

    let response = await fetch(CONTROLLER_API, {
        method:'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'JWT'+ userToken,
        }
      });

      let json = await response.json();
      await setCollectors(json);
      setController(json);
      if(typeof callback === 'function'){
        callback(json);
    }
  }


  const registerDataCollector = async (location, controllerId, collectorId) => {

    let CONTROLLER_API = COLLECTOR_REGISTER_PATH.concat(location).concat('/').concat(controllerId).concat('/').concat(collectorId);

    let response = await fetch(CONTROLLER_API, {
        method:'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'JWT'+ userToken,
        }
      });

      let json = await response.json();
  }

  const deregisterDataCollector = async(location, controllerId, collectorId) => {

    let CONTROLLER_API = COLLECTOR_DEREGISTER_PATH.concat(location).concat('/').concat(controllerId).concat('/').concat(collectorId);

    let response = await fetch(CONTROLLER_API, {
        method:'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'JWT'+ userToken,
        }
      });

      let json = await response.json();
  }


  return { controller, collectors, loadCollectors, registerDataCollector, deregisterDataCollector};
};

export default useDataCollectors;
