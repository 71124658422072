import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, TouchableOpacity, View, Image, Text } from "react-native";

import { HeaderText, BodyText } from "./Text";
import CartIcon from "./CartIcon";
import Icon from "./Icon";
import Colors from "../constants/Colors";
import { AddIcon, SaveIcon, QRCodeIcon } from "./Icons";
import { Header as HeaderRNE } from "react-native-elements";
import { DefaultTheme } from "../theme/DefaultTheme";
import { createDrawerNavigator } from "@react-navigation/drawer";
import {isDesktop} from "../constants/common";

const Header = ({
  title,
  style,
  showLogo,
  click,
  showBack,
  showMenu,
  showNotification,
  showCart,
  showSave,
  showAdd,
  onSave,
  onAdd,
  showQR,
  onQRPress,
}) => {
  return (
    <HeaderRNE
      containerStyle={DefaultTheme.header}
      leftComponent={
        <LeftComponent showBack={showBack} showMenu={showMenu} click={click} />
      }
      centerComponent={<CenterComponent title={title} />}
      rightComponent={
        <RightComponent
          showNotification={showNotification}
          showCart={showCart}
          showSave={showSave}
          showAdd={showAdd}
          onSave={onSave}
          onAdd={onAdd}
          showQR={showQR}
          onQRPress={onQRPress}
        />
      }
    ></HeaderRNE>
  );
};

const LeftComponent = ({ showBack, showMenu, click }) => {
  return (
    <View style={styles.leftContainer}>
      {showBack && <BackButton />}
      {isDesktop && showMenu && <MenuButton />}
      {click ? <StaticLogo /> : <ClickableLogo />}
    </View>
  );
};

const RightComponent = ({
  showNotification,
  showAdd,
  onAdd,
  showCart,
  showSave,
  onSave,
  showQR,
  onQRPress,
}) => {
  return (
    <View style={styles.rightContainer}>
      {showAdd && <AddButton onPress={onAdd} />}
      <View style={{ marginHorizontal: 3 }} />
      {showQR && <QRButton onPress={onQRPress} />}
      <View style={{ marginHorizontal: 3 }} />
      {showNotification && <Notification />}
    </View>
  );
};

const CenterComponent = ({ click, title }) => {
  return (
    <View style={styles.centerContainer}>
      <BodyText bold={'true'}>{title}</BodyText>
    </View>
  );
};

const StaticLogo = () => {
  return (
    <Image
      source={require("../assets/images/logo.png")}
      style={[{ marginLeft: 10 }, styles.logo]}
      resizeMode="contain"
    />
  );
};

const ClickableLogo = ({ title }) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={{ marginLeft: 10 }}
      onPress={() => navigation.navigate("LocationBottom")}
    >
      <Image
        source={require("../assets/images/logo.png")}
        style={styles.logo}
        resizeMode="contain"
      />
    </TouchableOpacity>
  );
};

const Notification = () => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity onPress={() => navigation.navigate("root")}>
      <View style={styles.backContainer}>
        <Icon name="bell" size={24} color="#00a859" />
      </View>
    </TouchableOpacity>
  );
};

const BackButton = () => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity onPress={() => navigation.goBack()}>
      <Icon name="arrow-left" size={24} color="#00a859" />
    </TouchableOpacity>
  );
};

const MenuButton = () => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity onPress={() => navigation.openDrawer()}>
      <Icon name="bars" size={24} color="#00a859" />
    </TouchableOpacity>
  );
};

const SaveButton = ({ onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.backContainer}>
        <SaveIcon />
      </View>
    </TouchableOpacity>
  );
};

const AddButton = ({ onPress }) => {

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.backContainer}>
        <AddIcon />
      </View>
    </TouchableOpacity>
  );
};

const QRButton = ({ onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.backContainer}>
        <QRCodeIcon focused={true} />
      </View>
    </TouchableOpacity>
  );
};

export default Header;

const styles = StyleSheet.create({
  heading: {
    color: "white",
    marginTop: 10,
    fontSize: 22,
    fontWeight: "bold",
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  leftContainer: {
    flex: 1,
    flexDirection: "row",
    borderColor: "#E5E5E5",
    alignItems: "center",
  },
  centerContainer: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: Colors.backgroundColor,
    borderColor: "#E5E5E5",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
  },
  rightContainer: {
    flex: 1,
    flexDirection: "row",
    borderColor: "#E5E5E5",
    alignItems: "center",
  },
  icon: {
    width: 24,
    height: 24,
    left: -2,
    resizeMode: "contain",
  },
  logo: {
    width: 60,
    height: 30,
  },
  saveContainer: {
    height: 40,
    borderWidth: 1,
    borderColor: "#E5E5E5",
    borderRadius: 10,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
  },
});
