import React, { useEffect } from 'react';
import FlexContainer from '../../components/FlexContainer';
import { useNavigation, useIsFocused } from "@react-navigation/native";
import constants from './constants';
import Card from './Card';

const Dashboard = ({ from }) => {
    const { navigate } = useNavigation();
    const isFocused = useIsFocused();
    useEffect(() => {
    }, [isFocused]);
    const items = [{
        titleKey: 'all',
        imageSrc: require(`../../assets/images/app-images/all_sensors.png`)
    }, {
        titleKey: 'soilMoisture',
        imageSrc: require(`../../assets/images/app-images/soil_moisture.png`),
        dataType: 'soilMoisture'
    }, {
        titleKey: 'ph',
        imageSrc: require(`../../assets/images/app-images/ph.png`),
        dataType: 'ph'
    }, {
        titleKey: 'temperature',
        imageSrc: require(`../../assets/images/app-images/temperature.png`),
        dataType: 'temperature'
    }, {
        titleKey: 'humidity',
        imageSrc: require(`../../assets/images/app-images/moisture.png`),
        dataType: 'humidity'
    }, {
        titleKey: 'sunlight',
        imageSrc: require(`../../assets/images/app-images/sunlight.png`),
        dataType: 'sunlight'
    }, {
        titleKey: 'rssi',
        imageSrc: require(`../../assets/images/app-images/rssi.png`),
        dataType: 'rssi'
    }, {
        titleKey: 'snr',
        imageSrc: require(`../../assets/images/app-images/snr.png`),
        dataType: 'snr'
    }, {
        titleKey: '',
        imageSrc: '',
        dataType: ''
    }];
    const moveToData = (dataType) => {
        navigate('data-dashboard', {
            from,
            dataType,
            viewType: constants.ViewType.Historical
        });
    };
    return (
        <FlexContainer flex={1} direction={'column'} marginTop={20}>
            <FlexContainer flex={1} direction={('row')} marginTop={10} marginBottom={10}>
                { (items || []).slice(0, 3).map(item => {
                    return (
                        <Card {...item} key={item.titleKey} onClick={moveToData}/>
                    );
                }) }
            </FlexContainer>
            <FlexContainer flex={1} direction={('row')} marginTop={10} marginBottom={10}>
                { (items || []).slice(3, 6).map(item => {
                    return (
                        <Card {...item} key={item.titleKey} onClick={moveToData}/>
                    );
                }) }
            </FlexContainer>
            <FlexContainer flex={1} direction={('row')} marginTop={10} marginBottom={10}>
                { (items || []).slice(6, 9).map(item => {
                    return (
                        <Card {...item} key={item.titleKey} onClick={moveToData}/>
                    );
                }) }
            </FlexContainer>
        </FlexContainer>
    );
};

export default Dashboard;
