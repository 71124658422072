import { useNavigation, useIsFocused } from "@react-navigation/native";
import React, { Component, useState, useEffect } from "react";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  View,
  Alert,
  BackHandler,  
} from "react-native";

import { ScrollView } from "react-native-gesture-handler";

import { BodyText, InfoText } from "../components/Text";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import FFAButton from "../components/FFAButton";
import FFAPasswordField from "../components/FFAPasswordField";

import OAuthButton from "../components/OAuthButton";
import TextLink from "../components/TextLink";
import {Auth} from 'aws-amplify';
import { useAuth } from "../hooks/useAuth";
import {isDesktop} from "../constants/common"
import Colors from "../constants/Colors";
import { LoginNameIcon } from "../components/Icons";
import { isLoading } from "expo-font";
import SocialLogin from "../components/SocialLogin";
import i18n from 'i18n-js';
import { DefaultTheme } from "../theme/DefaultTheme";
// import Snackbar from 'react-native-snackbar';


const LoginScreen = () => {
  const {navigate} = useNavigation();
  const {signIn} = useAuth();

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const isFocused = useIsFocused();
  const [snackBarVisible, setSnackBarVisible] = useState(false);

  const backAction = () => {
    Alert.alert("Hold on!", "Are you sure you want to go back?", [
      {
        text: "Cancel",
        onPress: () => null,
        style: "cancel"
      },
      { text: "YES", onPress: () => BackHandler.exitApp() }
    ]);
    return true;
  };

  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", backAction);

    return () => BackHandler.removeEventListener("hardwareBackPress", () => {});
  }, []);

 

function setErrorResponse(err) {
  if (! err.message) {
    setInfoMessage(true, err);
  } else {
    setInfoMessage(true, err.message);
  }
}

const successCallBack = (user) => {
  setUserName(null);
  setPassword(null);
  setLoading(false);
  // Snackbar.show({
  //   text: i18n.t('loginSuccessful'),
  //   duration: Snackbar.LENGTH_SHORT,
  // })
  navigate('app');
}

const errorCallBack = (err)  => {
  setErrorResponse(err);
  setLoading(false);
}

const setInfoMessage = (error, message) => {
  setError(error);
  setMessage(message);
  setSnackBarVisible(true);
}

const  handleSignIn = async() => {
  setShowMessage(true);
  if(username.length === 0){
    setInfoMessage(true, 'User Name should not be blank');
  } else if(password.length === 0){
    setInfoMessage(true, 'Password should not be blank');
  } else {
    setLoading(true);
    signIn(username, password, successCallBack, errorCallBack);
  }
}
  
  return (
        <SafeAreaView style={DefaultTheme.safeAreaContainer}>
        <Header showLogo title={i18n.t('login')} click={true}/>
        <View style={DefaultTheme.container}>
          <ScrollView showsVerticalScrollIndicator={false}>
            {showMessage  && 
            <>
            <InfoText error={isError}>{message}</InfoText>
            </>
            }
            <View style={{ marginVertical: 5 }} />
            <BodyText>{i18n.t('username')}</BodyText>
            <TextInput  textContentType="username" maxLength={15} value={username} onChangeText={(value) => setUserName(value)}/>
            <View style={{ marginVertical: 5 }} />
            <BodyText>{i18n.t('password')}</BodyText>
            <FFAPasswordField label={""} maxLength={15} value={password} onChangeText={(value) => setPassword(value)}/>
            <View style={{ marginVertical: 5 }} />
              <TextLink
                title={i18n.t('forgotPassword')+' ?'}
                bold={'true'}
                onPress={() => navigate("forgetpassword")}
                primary={'true'}
                right={'true'}
              />
            <View style={{ marginVertical: 10 }} />
            <FFAButton isLoading = {isLoading} title={i18n.t('login')} onPress={() => handleSignIn()}/>
            <View style={{ marginVertical: 5 }} />
            {/* <BodyText bold={true} center={true}>
            {i18n.t('or')}
            </BodyText> */}
            <View style={{ marginVertical: 5 }} />
            {/* <SocialLogin /> */}
            <View style={{ marginVertical: 15 }} />
            <TextLink
              bold={'true'}
              title={i18n.t('doNotHaveAnAccount')}
              onPress={() => navigate("signup")}
              primary={'true'}
            />
            <View style={styles.footer}>
              <BodyText>{i18n.t('copyrightText')}</BodyText>
            </View>
          </ScrollView>
        </View>
      </SafeAreaView>
  );
} 




export default LoginScreen;


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  text: {
    fontSize: 18,
    fontWeight: "bold",
    color:Colors.appColor
  },
  alertText: {
    fontSize: 18,
    fontWeight: "bold",
    color:Colors.appColor
  },
  footer: {
    bottom: 0,
    marginTop: 5,
    position: "relative"
  }
});


// export default function (props) {
  
  
//   return <LoginScreen {...props} signIn={signIn} />;
// }


