// import React from "react";
// import { View } from "react-native";
import LanguageListItem from "../components/LanguageListItem";
// import { ScrollView } from "react-native-gesture-handler";
// import { SafeAreaView } from "react-native-safe-area-context";
// import { HeaderText } from "../components/Text";
import i18n from "i18n-js";
// import { DefaultTheme } from "../theme/DefaultTheme";
import Header from "../components/Header";

import { useRoute, useNavigation } from "@react-navigation/native";
import React, { useState, useEffect } from "react";
import {
  SafeAreaView,
  View,
  AsyncStorage,
} from "react-native";

// import Header from "../../components/Header";
import { DefaultTheme } from '../theme/DefaultTheme';
import { ScrollView } from "react-native-gesture-handler";
import { HeaderText } from "../components/Text";
import { useIsFocused } from "@react-navigation/native";
import { useAuth } from "../hooks/useAuth";

const languages = [
  {
    locale: "en",
    name: "English",
  },
  {
    locale: "hn",
    name: "हिन्दी",
  },
  {
    locale: "mr",
    name: "मराठी",
  },
  {
    locale: "pa",
    name: "ਪੰਜਾਬੀ",
  },
  {
    locale: "te",
    name: "తెలుగు",
  },
  {
    locale: "bn",
    name: "বাংলা",
  },
];

const LanguageSelectorScreen = (props) => {
  const [currentLocale, setCurrentLocale] = useState('en');
  const isFocused = useIsFocused();
  const  navigation  = useNavigation();
  const {apiCurrentUser, updateLanguage} = useAuth();

  useEffect(() => {

    const getData = async () => {
      const value = await AsyncStorage.getItem('language');
      if (value !== null) {
        setCurrentLocale(value);
      }
    }
    getData();
  }, [isFocused]);


  const handleLanguage = async (locale) => {
    await updateLanguage(apiCurrentUser.username, locale);
    await AsyncStorage.setItem('language',locale);
    setCurrentLocale(locale);
    i18n.locale = locale;
    navigation.navigate("AccountBottom", {locale});
  };

  
    return (
      <SafeAreaView style={DefaultTheme.safeAreaContainer}>
      <Header showBack title={i18n.t('language')}/>
       <View style={DefaultTheme.container}>
       <ScrollView showsVerticalScrollIndicator={false}>
            <View style={{ marginTop: 5, marginVertical: 5 }}>
              {languages.map((language) => (
                <LanguageListItem
                  key={language.locale}
                  isActive={language.locale === currentLocale}
                  locale={language.locale}
                  name={language.name}
                  englishName={language.englishName}
                  onChangeLocale={(locale) => handleLanguage(locale)}
                />
              ))}
            </View>
          </ScrollView>
     </View>
  </SafeAreaView>
    );
  
}

export default LanguageSelectorScreen;
