import React from 'react';
import { useDashboard } from "../../hooks/useDashboard";
import {
    ActivityIndicator,
    View,
    StyleSheet
  } from "react-native";
import Colors from "../../constants/Colors";

import UserDashboard from './UserDashboard';
import { useRoute } from "@react-navigation/native";

const HistoricalDataDashboard = () => {
    const route = useRoute();
    const {
        isLoading,
        userType,
        isControllerDataFiltering,
        collectorDataError,
        isCollectorDataFiltering,
        controllerDataError,
        onFilterChange,
        currentCollectorData,
        currentControllerData,
        historicalCollectorData,
        hasMoreCollectorHistory,
        isFetchingMoreCollectorHistory,
        historicalControllerData,
        hasMoreControllerHistory,
        isFetchingMoreControllerHistory,
        onLoadMoreHistory
    }  = useDashboard();
    const Component = UserDashboard;
    const dataType = route.params && route.params.dataType;
    return (
        <React.Fragment>
            { userType ?
            <View style={styles.dashboardContainer}>
                { isLoading ? 
            (<ActivityIndicator size="large" color={Colors.appColor}  />) 
            : (<Component
                isControllerDataFiltering={isControllerDataFiltering}
                isCollectorDataFiltering={isCollectorDataFiltering}
                collectorDataError={collectorDataError}
                controllerDataError={controllerDataError}
                onFilterCriteriaChange={onFilterChange}
                onLoadMoreHistory = {onLoadMoreHistory}
                isFetchingMoreCollectorHistory={isFetchingMoreCollectorHistory}
                isFetchingMoreControllerHistory={isFetchingMoreControllerHistory}
                currentCollectorData={currentCollectorData}
                currentControllerData={currentControllerData}
                historicalCollectorData={historicalCollectorData}
                hasMoreCollectorHistory={hasMoreCollectorHistory}
                historicalControllerData={historicalControllerData}
                hasMoreControllerHistory={hasMoreControllerHistory}/>) }
            </View> : <ActivityIndicator size="large" color={Colors.appColor}  /> }
        </React.Fragment>
    );
};

export default HistoricalDataDashboard;

const styles = StyleSheet.create({
    dashboardContainer: {
    }
});
