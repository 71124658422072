import React, { useState, useEffect } from 'react';
import { View, ActivityIndicator, Dimensions } from "react-native";
import UserDashboardFilter from './UserDashboardFilter';
import CollectorData from './CollectorData';
import ControllerData from './ControllerData';
import Colors from "../../constants/Colors";
import FlexContainer from '../../components/FlexContainer';
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import i18n from 'i18n-js';
import { isDesktop } from "../../constants/common";
const initialLayout = { width: Dimensions.get('window').width };
import OperationalComponent from './OperationalComponent';



const UserDashboard = ({
    isControllerDataFiltering,
    collectorDataError,
    isCollectorDataFiltering,
    controllerDataError,
    onFilterCriteriaChange,
    onLoadMoreHistory,
    currentCollectorData,
    currentControllerData,
    historicalCollectorData,
    hasMoreCollectorHistory,
    isFetchingMoreCollectorHistory,
    historicalControllerData,
    hasMoreControllerHistory,
    isFetchingMoreControllerHistory
}) => {
    const [isFilterReady, setFilerReady] = useState(false);
    const [detailView, toggleHistoricalDetailView] = useState(isDesktop);
    const [graphView, toggleGraphView] = useState(false);
    const onFilterChange = (criteria) => {
        onFilterCriteriaChange(criteria);
    };

    const [index, setIndex] = useState(0);
    const [routes] = useState([
        { key: 'sensorData', title: i18n.t('recentSensorDataTitle') },
        { key: 'irrigationData', title: i18n.t('recentIrrigationData') },
    ]);

    const CollectorInfo = () => {
        return (
            <OperationalComponent
                isLoading={isCollectorDataFiltering}
                data={currentCollectorData}
                history={historicalCollectorData}
                hasMoreHistory={hasMoreCollectorHistory}
                onLoadMoreHistory={onLoadMoreHistory}
                isFetchingMoreHistory={isFetchingMoreCollectorHistory}
                error={collectorDataError}
                errorMessageKey={'collectorDataFetchError'}
                detailView={detailView}
                graphView={graphView}
                component={CollectorData}/>
        );
    }

    const IrrigationInfo = () => {
        return (
            <OperationalComponent
                isLoading={isControllerDataFiltering}
                data={currentControllerData}
                history={historicalControllerData}
                onLoadMoreHistory={onLoadMoreHistory}
                hasMoreHistory={hasMoreControllerHistory}
                isFetchingMoreHistory={isFetchingMoreControllerHistory}
                error={controllerDataError}
                errorMessageKey={'controllerDataFetchError'}
                detailView={detailView}
                graphView={graphView}
                component={ControllerData}/>
        );
    }

    const renderTabBar = props => (
        <TabBar
          {...props}
          indicatorStyle={{ backgroundColor: '#53302f' }}
          style={{ backgroundColor: '#dbb035' }}
        />
    );

    return (
        <View>
            <UserDashboardFilter onFilterChange={onFilterChange} onFilterReady={setFilerReady}
            canExpandCollapseHistory={(historicalControllerData && historicalControllerData.length > 0) ||
                (historicalCollectorData && historicalCollectorData.length > 0)}
            detailView={detailView} toggleHistoricalDetailView={(status) => toggleHistoricalDetailView(status)}
            graphView={graphView} toggleGraphView={(status) => toggleGraphView(status)}/>
            { !isFilterReady ? <ActivityIndicator size="large" color={Colors.appColor}  /> :
            <FlexContainer padding={0} direction={'column'}>
                 <TabView
                    renderTabBar={renderTabBar}
                    navigationState={{ index, routes }}
                    renderScene={SceneMap({
                        sensorData: CollectorInfo,
                        irrigationData: IrrigationInfo,
                    })}
                    onIndexChange={setIndex}
                    initialLayout={initialLayout}
                 />
            </FlexContainer>}
        </View>
    );
};

export default UserDashboard;