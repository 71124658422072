import React from 'react';
import IconName from '../constants/IconName';
import TabBarIcon from './TabBarIcon';
import Icon from './Icon';

export const HomeIcon = (props) => {return <TabBarIcon focused={props.focused} name={IconName.home} /> };
export const LocationIcon = (props) => {return <TabBarIcon focused={props.focused} name={IconName.location} /> };
export const DashboardIcon = (props) => {return <TabBarIcon focused={props.focused} name={IconName.dashboard} /> };
export const QRCodeIcon = (props) => {return <TabBarIcon focused={props.focused} name={IconName.qrCodeScan} /> };
export const HelpIcon = (props) => {return <TabBarIcon focused={props.focused} name={IconName.help} /> };
export const ProfileIcon = (props) => {return <TabBarIcon focused={props.focused} name={IconName.profile} /> };
export const LoginNameIcon = (props) => {return <Icon name={IconName.loginName} /> };
export const VisibilityIcon = (props) => {return <Icon name={IconName.visibility} /> };
export const VisibilityOffIcon = (props) => {return <Icon name={IconName.visibilityOff} /> };
export const AmplifierIcon = (props) => {return <Icon name={IconName.amplifier} /> };
export const SendIcon = (props) => {return <Icon {...props} name={IconName.send} /> };
export const AddIcon = (props) => {return <Icon {...props} name={IconName.add} /> };
export const SaveIcon = (props) => {return <Icon {...props} name={IconName.save} /> };
