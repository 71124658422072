import React from 'react';
import LineGraph from '../../components/Graphs/LineGraph';
import BarGraph from '../../components/Graphs/BarGraph';
import AreaGraph from '../../components/Graphs/AreaGraph';
import { isDesktop } from '../../constants/common';
export default (config, data) => {
    switch(config.graphType) {
        case 'Area':
            return <AreaGraph minValue={config.minValue}
                maxValue={config.maxValue}
                yValueKey={config.key}
                graphColor={config.graphColor}
                showTooltip={isDesktop}
                data={data}/>
        case 'Bar':
            return <BarGraph minValue={config.minValue}
                maxValue={config.maxValue}
                yValueKey={config.key}
                graphColor={config.graphColor}
                showTooltip={isDesktop}
                data={data}/>;
        case 'Line':
        default:
            return <LineGraph minValue={config.minValue}
                maxValue={config.maxValue}
                yValueKey={config.key}
                graphColor={config.graphColor}
                showTooltip={isDesktop}
                data={data}/>;
    }
};