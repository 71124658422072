import React from 'react';
import Constants from './constants';
import FlexContainer from '../../components/FlexContainer';
import i18n from 'i18n-js';
import ReadOnlyValue from '../../components/ReadOnlyValue';
import graphComponentFactory from './graph-component-factory';

const LineGraph = (props) => {
    const historyData = (props.historyData || []).slice(0, 5);
    return (
        <FlexContainer justifyContent="center">
            {
                Object.keys(Constants.sensorDataMap)
                    .filter(type => (!props.dataType || props.dataType === type) &&
                        !!Constants.sensorDataMap[type].showInGraph)
                    .map(type =>
                        <FlexContainer direction={'column'} key={type} >
                            {graphComponentFactory(Constants.sensorDataMap[type], historyData)}
                            <ReadOnlyValue
                                label={i18n.t(Constants.sensorDataMap[type].key)}
                                labelColor={Constants.sensorDataMap[type].graphColor}/>
                        </FlexContainer>
                )
            }
        </FlexContainer>
    );
};

export default LineGraph;