export const VictoryPie = require('victory').VictoryPie;
export const VictoryTooltip = require('victory').VictoryTooltip;
export const VictoryChart = require('victory').VictoryChart;
export const VictoryBar = require('victory').VictoryBar;
export const VictoryLabel = require('victory').VictoryLabel;
export const VictoryScatter = require('victory').VictoryScatter;
export const VictoryPolarAxis = require('victory').VictoryPolarAxis;
export const VictoryTheme = require('victory').VictoryTheme;
export const VictoryAxis = require('victory').VictoryAxis;
export const VictoryLine = require('victory').VictoryLine;
export const VictoryClipContainer = require('victory').VictoryClipContainer
export const VictoryGroup = require('victory').VictoryGroup;
export const VictoryCursorContainer = require('victory').VictoryCursorContainer;
export const VictoryArea = require('victory').VictoryArea;