import React from "react";
// import { createSwitchNavigator, createAppContainer } from 'react-navigation';
import AuthLoadingScreen from "./AuthLoadingScreen";
import AuthStackNavigator from "./AuthStackNavigator";
import AppNavigation from "./AppNavigation";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";
import { useAuth } from "../hooks/useAuth";
import AppDrawerNavigation from "./AppDrawerNavigation";
//import I18n from 'react-native-i18n';
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import SplashScreen from "../screens/Splash";
import ProductDetailScreen from "../screens/ProductDetail";
import CartScreen from "../screens/Cart";
import EditPersonalInfoScreen from "../screens/EditPersonalInfo";
import AddressesScreen from "../screens/AddressesScreen";
import ShippingAddressScreen from "../screens/ShippingAddress";
import SignupScreen from "../screens/Signup";
import ForgetPassword from "../screens/ForgetPassword";
import ControllerScreen from "../screens/location/ControllerScreen";
import RegistrationScreen from "../screens/registration/RegistrationScreen";
import ChangePassword from "../screens/ChangePassword";
import VerifyOTPScreen from "../screens/VerifyOTP";
import AddPersonalInfoScreen from "../screens/AddPersonalInfo";
import DataCollectorScreen from "../screens/location/DataCollectorScreen";
import LanguageSelectorScreen from "../screens/LanguageScreen";
import { AsyncStorage } from "react-native";

const Stack = createStackNavigator();

function AppContainer() {
  const { isLoading } = useAuth();

  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        {isLoading ? (
          <Stack.Screen name="splash" component={SplashScreen} />
        ) : (
        <>
        <Stack.Screen name="authLoading" component={AuthLoadingScreen} />
        <Stack.Screen name="auth" component={AuthStackNavigator} />
        <Stack.Screen name="drawer" component={AppDrawerNavigation} />
        <Stack.Screen name="app" component={AppNavigation} />
        <Stack.Screen name="products" component={ProductDetailScreen} />
        <Stack.Screen name="cart" component={CartScreen} />
        <Stack.Screen name="edit-personal-info" component={EditPersonalInfoScreen} />
        <Stack.Screen name="addresses" component={AddressesScreen} />
        <Stack.Screen name="shipping-address" component={ShippingAddressScreen} />
        <Stack.Screen name="controller" component={ControllerScreen} />
        <Stack.Screen name="controller-registration" component={RegistrationScreen} />
        <Stack.Screen name="change-password" component={ChangePassword} />
        <Stack.Screen name="verify-otp" component={VerifyOTPScreen} />
        <Stack.Screen name="add-personal-info" component={AddPersonalInfoScreen} />
        <Stack.Screen name="data-collector" component={DataCollectorScreen} />
        <Stack.Screen name="language" component={LanguageSelectorScreen} />
        </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default AppContainer;
