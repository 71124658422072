const tintColor = '#2f95dc';
const commonColor = "#00a859";



const antDesignName = {
    home:"home",
    location:"map-marker-outline",
    dashboard:"view-dashboard",
    help:"help-box",
    profile:"account",
    loginName:"login",
    visibilityOff:"visibility-off",
    visibility:"visibility",
    amplifier:"amplifier",
}

const MaterialCommunityIcon = {
    home:"home",
    location:"map-marker-outline",
    dashboard:"view-dashboard",
    help:"help-box",
    profile:"account",
    loginName:"account-tie",
    visibilityOff:"visibility-off",
    visibility:"visibility",
    amplifier:"amplifier",
    send:"send",
    qrCodeScan:"qrcode-scan",
    add:"plus-circle",
    save:"content-save",
    language:"language"
}

const FontAwesome5Icon = {
  home:"home",
  location:"map-marker-alt",
  dashboard:"th",
  help:"question",
  profile:"user-alt",
  loginName:"account-tie",
  visibilityOff:"eye-slash",
  visibility:"eye",
  amplifier:"microchip",
  send:"save",
  qrCodeScan:"qrcode",
  add:"plus-circle",
  save:"content-save",
  language:"language",
  fingerprint:"fingerprint",
  signOut:"sign-out-alt"
}


const IoniconsIcon = {
  home:"md-home",
  location:"map-pin",
  dashboard:"th",
  help:"question",
  profile:"user-alt",
  loginName:"account-tie",
  visibilityOff:"eye-slash",
  visibility:"eye",
  amplifier:"amplifier",
  send:"md-send",
  qrCodeScan:"qrcode",
  add:"ios-add-circle",
  save:"content-save",
  language:"language",
  fingerprint:"md-finger-print",
  signOut:"sign-out-alt"
}

const name = {...FontAwesome5Icon};

export default {
  home:name.home,
  location:name.location,
  dashboard:name.dashboard,
  qrCode:name.dashboard,
  help:name.help,
  profile:name.profile,
  loginName:name.loginName,
  visibilityOff:name.visibilityOff,
  visibility:name.visibility,
  amplifier:name.amplifier,
  send:name.send,
  qrCodeScan:name.qrCodeScan,
  add:name.add,
  save:name.save,
  language:name.language,
  fingerprint:name.fingerprint,
  signOut:name.signOut
};