import React, { useEffect } from "react";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  View
} from "react-native";

import Header from "../components/Header";
import UsersData from './Dashboard/UsersData';
import { DefaultTheme } from "../theme/DefaultTheme";


const DataDashboard = () => {

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>

      <Header showLogo showMenu showNotification showBack/>

      <View style={DefaultTheme.container}>
        <UsersData />
      </View>
    </SafeAreaView>
  );
};

export default DataDashboard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    paddingTop: Platform.OS == "ios" ? 20 : StatusBar.currentHeight + 15,
    paddingHorizontal: 25,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  thumbnail: {
    height: 150,
    borderRadius: 10,
    resizeMode: "cover",
  },
});