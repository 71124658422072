import { useNavigation } from "@react-navigation/native";
import React, { Component } from "react";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  Alert,
  View,
  CheckBox,
  TouchableOpacity,
  Linking
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import { HeaderText, BodyText, InfoText } from "../components/Text";
import Header from "../components/Header";
import TextInput from "../components/TextInput";
import FFAButton from "../components/FFAButton";
import OAuthButton from "../components/OAuthButton";
import TextLink from "../components/TextLink";
import {Auth, API} from 'aws-amplify';
import FFAPasswordField from "../components/FFAPasswordField";
import { isLoaded, isLoading } from "expo-font";
import SocialLogin from "../components/SocialLogin";
import Helper from "../utils/Helper";
import i18n from 'i18n-js';
import { DefaultTheme } from "../theme/DefaultTheme";
import Colors from "../constants/Colors";
import {USERS_API} from '../constants/AWSApiUrlConstant';
import { Picker } from "@react-native-community/picker";
import { isWeb } from '../constants/common';

const openURL = (url) => {
  if (isWeb) {
    window.open(url, '_blank');
  } else {
    Linking.openURL(url);
  }
}

export default class SignupScreen extends Component {

  state = {
    username: '',
    password: '',
    email: '',
    phoneNumber: '',
    isNotConfirmedCode: true,
    authCode: '',
    isLoading: false,
    isError: false,
    showMessage: false,
    message:'',
    userType:'User',
    isAgreed: true
  }
  

  onChangeText(key, value) {
    this.setState({
      [key]: value
    })
  }

  setUserType(value){
    this.setState({userType : value});
  }


  setError(value) {
    this.setState({isError : value});
  }

  setMessage(value) {
    this.setState({
      message : value
    })
  }


  setErrorResponse(err) {
    if (!err.message) {
      this.setError(true);
      this.setMessage(err);
    } else {
      this.setError(true);
      this.setMessage(err.message);
    }
  }

  onAgreementChange(agreed) {
    this.setState({
      isAgreed: agreed
    });
  }

  async signUp() {
    const { username, password, email, phoneNumber, userType } = this.state
    
    // rename variable to conform with Amplify Auth field phone attribute
    this.onChangeText('isLoading', true);
    this.onChangeText('showMessage', true);
    if(username.length === 0){
      this.setError(true);
      this.setMessage('User Name should not be blank');
    } else if(password.length < 8){
      this.setError(true);
      this.setMessage('Password should not be blank or less than 8 char');
    }else if(phoneNumber.length < 10){
      this.setError(true);  
      this.setMessage('Enter correct phone number');
    }else if(email.length > 0 && !Helper.isEmailValid(email)){
      this.setError(true);  
      this.setMessage('Email is Not Correct');
  }else if(!Helper.isValidCharacter(username)){
      this.setError(true);  
      this.setMessage('Special character are not allowed in Name field.');
  }
  else if (!this.state.isAgreed) {
    this.setError(true);
    this.setMessage('Please Check I Agree Checkbox');
  } else{
      this.setError(false);
      
      const phone_number = '+91' +phoneNumber;
      const profile = userType;
      await Auth.signUp({
        username,
        password,
        attributes: { email, phone_number, profile }
      })
      .then(() => {
        this.onChangeText('isLoading', false);
        this.onChangeText('isNotConfirmedCode', false);
        this.setError(false);
        this.setMessage('Enter the confirmation code you received');
      })
      .catch(err => {
        this.setErrorResponse(err);
        this.onChangeText('isLoading', false);
        this.onChangeText('isNotConfirmedCode', true);
      })
    }
    this.onChangeText('isLoading', false);
  }

  async signIn ()  {
    const { username, password } = this.state;
    await Auth.signIn(username, password)
    .then(user => {
      this.createAPIUser(user);
      
    })
    .catch(err => {
      Alert.alert(err.message);
    })
  }


  async addToGroup(user) { 
    let apiName = 'AdminQueries';
    let path = '/addUserToGroup';
    let myInit = {
        body: {
          "username" : this.state.username,
          "groupname": "user"
        }, 
        headers: {
          'Content-Type' : 'application/json',
          Authorization: user.signInUserSession.accessToken.jwtToken
        } 
    }

    await API.post(apiName, path, myInit).then(result => {
      //createAPIUser(user);
    });
  }
  

  async createAPIUser(user) {
      let url = USERS_API;
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'JWT'+ user.signInUserSession.accessToken.jwtToken,
        },
        body: JSON.stringify(
          {
            "username": user.username,
            "group": "user"
          }
        )
      }).then( () => {
        this.onChangeText('isLoading', false);
        Alert.alert('Confirm sign up successful');
        this.props.navigation.navigate('login')
      });
  }
  
  // Confirm users and redirect them to the SignIn page
  async confirmSignUp() {
    const { username, authCode } = this.state

    if(authCode.length < 6){
        this.setError(false);
        this.setMessage('confirmation code is not correct.');
    }else {
      this.onChangeText('isLoading', true);
      await Auth.confirmSignUp(username, authCode)
      .then((res) => {
        this.signIn(); 
      })
      .catch(err => {
        this.setErrorResponse(err);
        this.onChangeText('isLoading', false);
      })
    }
    
  }
  // Resend code if not received already
  async resendSignUp() {
    const { username } = this.state
    await Auth.resendSignUp(username)
    .then(() => this.setMessage('Confirmation code resent successfully'))
    .catch(err => {
      this.setErrorResponse(err);
    })
  }

  
  render() {
    return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
        <Header title="Sign up" showBack   click={true}/>
        <View style={DefaultTheme.container}>
          <ScrollView showsVerticalScrollIndicator={false}>
            {this.state.showMessage && <InfoText error={this.state.isError}>{this.state.message}</InfoText>}
            <View style={{ marginVertical: 10 }} />
            <BodyText>{i18n.t('username')}</BodyText>
            <TextInput textContentType="username" maxLength={15} editable={this.state.isNotConfirmedCode}  onChangeText = {value => this.onChangeText('username', value)}/>
            <View style={{ marginVertical: 5 }} />
            <BodyText>{i18n.t('password')}</BodyText>
            <FFAPasswordField maxLength={15} editable={this.state.isNotConfirmedCode} onChangeText={(value) => this.onChangeText('password', value)}/>
            <View style={{ marginVertical: 5 }} />
            <BodyText>{i18n.t('emailAddress')}</BodyText>
            <TextInput textContentType="emailAddress" keyboardType="email-address" editable={this.state.isNotConfirmedCode} maxLength={60} onChangeText = {value => this.onChangeText('email', value)}/>
            <View style={{ marginVertical: 5 }}/>
            <BodyText>{i18n.t('phoneNumber')}</BodyText>
            <View style={{flexDirection: "row"}}>
              <TextInput editable={false} style={{width:35}} value ={'+91'} />
              <TextInput keyboardType="phone-pad" maxLength={10} style={{flex:1}} editable={this.state.isNotConfirmedCode} onChangeText = {value => this.onChangeText('phoneNumber', value)}/>
            </View>
            <View style={{ marginVertical: 5 }} />
            <BodyText>{i18n.t('userType')}</BodyText>
            <Picker
              selectedValue={this.state.userType}
              style={{ height: 50, width: '100%', borderTopWidth: 0,borderLeftWidth: 0,borderRightWidth: 0, borderBottomWidth: 1,
              fontFamily: null,
              fontSize: 18,
              color: '#102e39',
              borderBottomColor: Colors.borderColor, }}
              onValueChange={(itemValue, itemIndex) => this.setUserType(itemValue)}
            >
              <Picker.Item label="User" value="User" />
              <Picker.Item label="Farmer" value="Farmer" />
              <Picker.Item label="Nursery" value="Nursery" />
              <Picker.Item label="Agri Expert" value="AgriExpert" />
              <Picker.Item label="Agri Business" value="AgriBusiness" />
              <Picker.Item label="Agri Input" value="AgriInput" />
              <Picker.Item label="Agri Student" value="AgriStudent" />

            </Picker>
            <View style={{
              marginVertical: 10,
              display: 'flex',
              alignItems: 'stretch',
              justifyContent: 'flex-start',
              flexDirection: 'row'
            }}>
              <CheckBox value={this.state.isAgreed}
                onChange={() => this.onAgreementChange(!this.state.isAgreed)} />
              <BodyText>{` ${i18n.t('iAgreeText')} `}</BodyText> 
              <TouchableOpacity onPress={() => openURL(`https://farmingforall.in/privacypolicy.html`)}>
                <BodyText color={'#00a859'} style={{
                  textDecorationLine: 'underline'
                }}>{`${i18n.t('privacyPolicy')}`}</BodyText>
              </TouchableOpacity>
              <BodyText>{` ${i18n.t('and')} `}</BodyText>
              <TouchableOpacity onPress={() => openURL(`https://farmingforall.in/termsofservice.html`)}>
                <BodyText color={'#00a859'} style={{
                  textDecorationLine: 'underline'
                }}>{`${i18n.t('termsOfUse')}`}</BodyText>
              </TouchableOpacity>
            </View>         
            <View style={{ marginVertical: 5 }} />
            <FFAButton
              title={i18n.t('signUp')}
              isLoading={this.state.isNotConfirmedCode && this.state.isLoading}
              disabled={!this.state.isNotConfirmedCode || this.state.isLoading}
              onPress={() => this.signUp()}              
            />
            <View style={{ marginVertical: 5 }} />
            <BodyText>{i18n.t('confirmationCode')}</BodyText>
            <TextInput keyboardType="numeric" maxLength={6} editable={!this.state.isNotConfirmedCode} onChangeText = {value => this.onChangeText('authCode', value)}/>
            <View style={{ marginVertical: 5 }} />
            {
              this.state.isNotConfirmedCode ? 
              <BodyText bold={'true'} right={true}>{i18n.t('resendCode')}</BodyText> 
              :
              <TextLink
                disabled={this.state.isNotConfirmedCode}
                title={i18n.t('resendCode')}
                onPress={() => this.resendSignUp()}
                primary={'true'}
                right={true}
                bold={'true'}
              />

            }
            <View style={{ marginVertical: 10 }} />  
            <FFAButton
              title={i18n.t('confirmSignUp')}
              disabled={this.state.isNotConfirmedCode || this.state.isLoading}
              isLoading={!this.state.isNotConfirmedCode && this.state.isLoading}
              onPress={() => this.confirmSignUp()}
            />
            <View style={{ marginVertical: 5 }} />
            {/* <BodyText bold center>{i18n.t('or')}</BodyText>
            <View style={{ marginVertical: 5 }} /> */}
            {/* <SocialLogin /> */}
            <View style={{ marginVertical: 5 }} />
            <TextLink
              bold={true}
              title={i18n.t('alreadyHaveAnAccount')}
              onPress={() => this.props.navigation.navigate("login")}
              primary
            />
             <View style={{ marginVertical: 5 }} />
            <BodyText>{i18n.t('copyrightText')}</BodyText>
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  }
  
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    paddingTop: Platform.OS == "ios" ? 20 : StatusBar.currentHeight + 15,
    paddingHorizontal: 25,
  },
});
