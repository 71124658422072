import React, { useEffect } from 'react';
import { useDashboard } from "../../hooks/useDashboard";
import {
    ActivityIndicator,
    View,
    StyleSheet
  } from "react-native";
  import Colors from "../../constants/Colors";

import UserDashboard from './UserDashboard';

const Dashboard = () => {
    const {
        isLoading,
        userType,
        isControllerDataFiltering,
        collectorDataError,
        isCollectorDataFiltering,
        controllerDataError,
        onFilterChange,
        currentCollectorData,
        currentControllerData,
        resetAutoRefresher
    }  = useDashboard();
    useEffect(() => {
        return () => {
            resetAutoRefresher();
        };
    }, []);
    const Component = UserDashboard;
    return (
        <React.Fragment>
            { userType ?
            <View style={styles.dashboardContainer}>
                { isLoading ? 
            (<ActivityIndicator size="large" color={Colors.appColor}  />) 
            : (<Component
                isControllerDataFiltering={isControllerDataFiltering}
                isCollectorDataFiltering={isCollectorDataFiltering}
                collectorDataError={collectorDataError}
                controllerDataError={controllerDataError}
                onFilterCriteriaChange={onFilterChange}
                currentCollectorData={currentCollectorData}
                currentControllerData={currentControllerData}/>) }
            </View> : <ActivityIndicator size="large" color={Colors.appColor}  /> }
        </React.Fragment>
    );
};

export default Dashboard;

const styles = StyleSheet.create({
    dashboardContainer: {
    }
});
