import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  View,
  FlatList,
  TouchableOpacity,
  Image,
  Text,
} from "react-native";

import { BodyText, PriceText } from "../components/Text";
import Header from "../components/Header";
import FFASwiper from "../components/FFASwiper";
import Dashboard from './Dashboard';

import useProducts from "../hooks/useProducts";
import { ScrollView } from "react-native-gesture-handler";
import { DefaultTheme } from "../theme/DefaultTheme";





const HomeScreen = ({navigation}) => {
  const { products } = useProducts();
  // const {navigation} = useNavigation()


  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>

      <Header showLogo showMenu showNotification />

      <View style={DefaultTheme.container}>
        {/* <Header showLogo showNotification /> */}
        <ScrollView
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          removeClippedSubviews={false}
        >
          <View style={{paddingVertical:10}} />
          <FFASwiper />
          <Dashboard />
            <View style={{flex:1, flexDirection:"column", justifyContent:"space-evenly"}} >
          </View>
   

        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const ProductList = () => {
  const { products } = useProducts();

  return (
    <SafeAreaView>
      <FlatList
        data={products}
        renderItem={({ item }) => <ProductItem product={item} />}
        keyExtractor={(item) => item.id}
        // numColumns={2}
        horizontal={true}
        // columnWrapperStyle={{ marginVertical: 15 }}
        style={{flex: 1, marginHorizontal: -8 }}
      />
    </SafeAreaView>
  );
};

const ProductItem = ({ product }) => {
  const { title, image, price } = product;
  const { navigate } = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => navigate("products", { product: product })}
      style={{ flex: 1, paddingHorizontal: 8 }}
    >
      <View>
        <Image source={{ uri: image }} style={styles.thumbnail} />
        {/* <Image source= {require({image})} style={styles.thumbnail} />  */}

        <View style={{ marginVertical: 5 }} />
        <BodyText bold>{title}</BodyText>
      </View>
    </TouchableOpacity>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    paddingTop: Platform.OS == "ios" ? 20 : StatusBar.currentHeight + 15,
    paddingHorizontal: 25,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  thumbnail: {
    height: 150,
    borderRadius: 10,
    resizeMode: "cover",
  },
});