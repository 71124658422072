import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import BottomTabNavigator from "./BottomTabNavigator";
import { useAuth } from "../hooks/useAuth";

import SplashScreen from "../screens/Splash";
import LoginScreen from "../screens/Login";
import SignupScreen from "../screens/Signup";
import VerifyOTPScreen from "../screens/VerifyOTP";
import AddPersonalInfoScreen from "../screens/AddPersonalInfo";
import ProductDetailScreen from "../screens/ProductDetail";
import CartScreen from "../screens/Cart";
import EditPersonalInfoScreen from "../screens/EditPersonalInfo";
import ShippingAddressScreen from "../screens/ShippingAddress";
import HomeScreen from "../screens/Home";
import AccountScreen from "../screens/Account";
import ForgetPassword from "../screens/ForgetPassword";
import ChangePassword from "../screens/ChangePassword";
import { DefaultTheme } from "../theme/DefaultTheme";
import ControllerScreen from "../screens/location/ControllerScreen";
import DataCollectorScreen from "../screens/location/DataCollectorScreen";
import AddressesScreen from "../screens/AddressesScreen";
import RegistrationScreen from "../screens/registration/RegistrationScreen";
import LocationScreen from "../screens/location/LocationScreen";
import LanguageScreen from "../screens/LanguageScreen";
import DataDashboard from "../screens/DataDashboard";
import UserListDataDashboard from '../screens/UserListDataDashboard';

const Stack = createStackNavigator();

const AppNavigation = () => {
  const { isLoading } = useAuth();

  return (
    
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}>
            <Stack.Screen name="root" component={BottomTabNavigator} />
            {/* <Stack.Screen name="home" component={HomeScreen} /> */}
            {/* <Stack.Screen name="account" component={AccountScreen} /> */}
            <Stack.Screen name="products" component={ProductDetailScreen} />
            <Stack.Screen name="cart" component={CartScreen} />
            <Stack.Screen name="edit-personal-info" component={EditPersonalInfoScreen} />
            <Stack.Screen name="addresses" component={AddressesScreen} />
            <Stack.Screen name="shipping-address" component={ShippingAddressScreen} />
            {/* <Stack.Screen name="login" component={LoginScreen} /> */}
            {/* <Stack.Screen name="signup" component={SignupScreen} /> */}
            {/* <Stack.Screen name="forgetpassword" component={ForgetPassword} /> */}
            <Stack.Screen name="controller" component={ControllerScreen} />
            <Stack.Screen name="controller-registration" component={RegistrationScreen} />
            <Stack.Screen name="change-password" component={ChangePassword} />
            {/* <Stack.Screen name="verify-otp" component={VerifyOTPScreen} /> */}
            <Stack.Screen name="add-personal-info" component={AddPersonalInfoScreen} />
            <Stack.Screen name="data-collector" component={DataCollectorScreen} />
            <Stack.Screen name="data-dashboard" component={DataDashboard} />
            <Stack.Screen name="user-lists-data-dashboard" component={UserListDataDashboard} />
            <Stack.Screen name="language" component={LanguageScreen} />
      </Stack.Navigator>
    
  );
};

export default AppNavigation;
