import React from 'react';
import FlexContainer from '../../components/FlexContainer';
import { BodyText } from '../../components/Text';
import i18n from 'i18n-js';
import { TouchableOpacity, Image } from "react-native";
export default ({titleKey, imageSrc, dataType, onClick}) => {
    return (
        <FlexContainer flex={1} justifyContent={'center'} alignItems={'center'} direction={'column'} marginBottom={10}>
            { imageSrc ? <TouchableOpacity onPress={() => onClick(dataType)}>
                <Image source={imageSrc} style={
                    {
                        width: 36,
                        height: 36
                    }
                } />
            </TouchableOpacity> : null }
            { titleKey ? <BodyText size={16}
                center={ (true).toString()}>
                {i18n.t(titleKey)}
            </BodyText> : null }
        </FlexContainer>
    );
};