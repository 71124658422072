import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as React from 'react';

import Colors from '../constants/Colors';
import { Icon } from 'react-native-elements';


export default function TabBarIcon(props) {
  return (

    <Icon
    type="font-awesome-5" 
    name = {props.name} 
    style={{ marginBottom: -3 }}
    color={props.focused ? Colors.tabIconSelected : Colors.tabIconDefault}
    />
  );
}
