import React from 'react';
import { View } from "react-native";
import { BodyText } from './Text';
import { isDesktop } from "../constants/common";

const ReadOnlyValue = ({
    label, value, direction, labelColor, valueColor, labelSize, valueSize, noBoldLabel,
    noTopMargin, boldValue
}) => {
    const showInCoulmn = direction !== 'row';
    return (
        <View style={{
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            flexDirection: direction || 'column',
            paddingTop: 10,
            paddingBottom: 10
         }}>
            <BodyText bold={!noBoldLabel}
                color={labelColor}
                size={labelSize || ''}
                center={ (isDesktop ? false : showInCoulmn).toString()}>
                    {label}
            </BodyText>
            <BodyText size={!showInCoulmn ? 16 : (valueSize || '')} style={
                {
                    marginLeft: showInCoulmn ? 0 : 5,
                    marginTop: showInCoulmn && !noTopMargin ? 15 : 0
                }
            }
                bold={boldValue}
                color={valueColor}
                center={ (isDesktop ? showInCoulmn : false).toString()}>
                {value}
            </BodyText>
        </View>
    );
};

export default ReadOnlyValue;