import { useState, useEffect} from "react";
import { ADDRESS_API } from "../constants/AWSApiUrlConstant";
import { useAuth } from "./useAuth";


const useAddress = () => {
  // const { tempAddresses } = useContext(AddressContext);
  const { userToken, apiCurrentUser } = useAuth();

  const [addresses, setAddresses] = useState([]);
  const [isFetching, setFetching] = useState(false);


  let ADDRESS_API_PATH = ADDRESS_API.concat("/").concat(apiCurrentUser.username);

  useEffect(() => {
    loadAddresses();
  }, []);

  const loadAddresses = async () => {
    
    let url = ADDRESS_API_PATH;


    let response = await fetch(url, {
        method:'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'JWT'+ userToken,
        }
      });

      let json = await response.json();
      setAddresses(Object.values(json));
      return Object.values(json);
  }

  const editAddress = (
    addressName,
    houseNo,
    street,
    city,
    state,
    zip,
    country,
    isPrimary
  ) => {



    let url = ADDRESS_API.concat("/").concat(apiCurrentUser.username).concat("/").concat(addressName);

    let address = JSON.stringify({
      houseNo: houseNo,
      street: street,
      city: city,
      state: state,
      zip: zip,
      country: country,
      addressName: addressName,
      isPrimary: isPrimary,
    });

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "JWT" + userToken,
      },
      body: address,
    }).then();
  };

  return {  addresses, loadAddresses, editAddress };
};

export default useAddress;
