import React from "react";
import { StyleSheet } from "react-native";
import { Button } from "react-native-elements";
import  Colors from '../constants/Colors';

const FFAButton = ({
  title,
  onPress,
  btnStyle,
  textSize,
  containerStyle,
  icon,
  iconRight,
  isLoading,
  disabled,
}) => {
  return <Button
  containerStyle={[containerStyle, styles.containerStyle]} 
  title={title} 
  titleStyle={[styles.titleStyle]} 
  type=  "solid"
  onPress={onPress} 
  buttonStyle={[btnStyle, styles.btnStyle,]}
  icon= {icon}
  iconContainerStyle= {styles.iconContainerStyle}
  iconRight={iconRight}
  loading = {isLoading}
  disabled = {disabled} 
  />;
};



export default FFAButton;

const styles = StyleSheet.create({
  container: {
    height: 41.25,
    flexDirection: "row",
    alignItems: "center",
    justifyContent:"space-between",
    borderRadius: 10,
    paddingHorizontal: 10,
  },

  iconContainerStyle: {
    marginHorizontal:5
  },
  icon: {
    width: 24,
    height: 24,
    tintColor: "#FFF",
  },
  // titleStyle: {
  //   color: "#0ed145",
  // },
  btnStyle: {
    borderColor:Colors.borderColor,
    color:Colors.borderColor,
    borderRadius: 10,
    backgroundColor:Colors.borderColor,
  }
});
