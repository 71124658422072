import React from 'react';
import { ActivityIndicator } from "react-native";
import Colors from "../../constants/Colors";
import NoRecord from './NoRecord';
export default (props) => {
    const {
        isLoading,
        data,
        component,
        error,
        errorMessageKey,
        history,
        withoutData
    } = props;
    const CompToRender = component;
    return (
        <React.Fragment>
            {isLoading ? <ActivityIndicator size="large" color={Colors.appColor} style={{
                marginTop: 30,
                marginBottom: 30
            }}  /> :
                    (withoutData || data || (history && history.length > 0 ) ?
                    <CompToRender {...data} historyData={history} {...props}/> :
                    <NoRecord messageKey={error ? errorMessageKey : ''}/>)}
        </React.Fragment>
    );
};