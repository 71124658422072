import React, { useEffect } from 'react';
import FlexContainer from '../../components/FlexContainer';
import { useNavigation, useIsFocused } from "@react-navigation/native";
import Card from './Card';

const Dashboard = ({ from }) => {
    const { navigate } = useNavigation();
    const isFocused = useIsFocused();
    useEffect(() => {
    }, [isFocused]);
    const items = [{
        titleKey: 'userList',
        imageSrc: require(`../../assets/images/user-light.png`),
        dataType: 'userList'
    }];
    const moveToData = (dataType) => {
        navigate('user-lists-data-dashboard', {
            from,
            dataType
        });
    };
    return (
        <FlexContainer flex={1} direction={'column'} marginTop={20}>
            <FlexContainer flex={1} direction={('row')} marginTop={10} marginBottom={10}>
                { (items || []).map(item => {
                    return (
                        <Card {...item} key={item.titleKey} onClick={moveToData}/>
                    );
                }) }
            </FlexContainer>
        </FlexContainer>
    );
};

export default Dashboard;
