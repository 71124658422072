import React, { useEffect } from 'react';
import FlexContainer from '../../components/FlexContainer';
import Button from '../../components/Button'
import { useRoute } from "@react-navigation/native";
import CurrentData from './CurrentData';
import HistoricalData from './HistoricalData';
import i18n from 'i18n-js';
import { useNavigation, useIsFocused } from "@react-navigation/native";
import constants from './constants';
const Landing = () => {
    const route = useRoute();
    const { navigate } = useNavigation();
    const isFocused = useIsFocused();
    useEffect(() => {
    }, [isFocused]);
    const Component = route.params && route.params.viewType === 'Historical' ? HistoricalData :
        route.params && route.params.viewType === 'Current' ? CurrentData : null;
    return (
        <React.Fragment>
            { Component ? <Component /> :
                <FlexContainer justifyContent={'space-evenly'}>
                    <Button title={i18n.t('currentData')} primary btnStyle={{
                        marginTop: 10,
                        height: 200
                    }} onPress={() => {
                        navigate('data-dashboard', {
                            viewType: constants.ViewType.Current
                        });
                    }}/>
                    <Button title={i18n.t('historicalData')} primary btnStyle={{
                        marginTop: 10,
                        height: 200
                    }} onPress={() => {
                        navigate('data-dashboard', {
                            viewType: constants.ViewType.Historical
                        });
                    }}/>
                </FlexContainer>
            }
        </React.Fragment>
    );
};

export default Landing;