import React, { useEffect } from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import LocationScreen from "../screens/location/LocationScreen";
import HomeScreen from "../screens/Home";
import AccountScreen from "../screens/Account";
import BottomTabNavigator from "./BottomTabNavigator";
import { DrawerContent } from "./DrawerContent";
import { useNavigation, TabActions } from "@react-navigation/native";
import { ActivityIndicator, View } from "react-native";
import Colors from "../constants/Colors";
import CustomDrawerContent from "./DrawerContentComponent";

const Drawer = createDrawerNavigator();

export default function AppDrawerNavigation() {
  const { navigate } = useNavigation();

  return (
    <Drawer.Navigator
    drawerContent={ props => <CustomDrawerContent {...props} />}
    drawerContentOptions={{
          activeTintColor: Colors.tabIconSelected,
          activeBackgroundColor: 'transparent',
          inactiveTintColor: Colors.inactiveTintColor,
          inactiveBackgroundColor: 'transparent',
          backgroundColor: '#ffffff',
          labelStyle: {
            fontSize: 15,
            marginLeft: 0,
          },
        }}
    >
    <Drawer.Screen name="Home" component={BottomTabNavigator} />
    </Drawer.Navigator>
  );
}