import { createContext, useContext } from "react";

const initialData = [
  {
    id: "1",
    title: "Dashboard 1",
    image: "https://i.pinimg.com/564x/fc/12/4b/fc124b9da4b20d092d30d79642afe196.jpg",
    price: 35000,
    size: "47-59”",
    description:
      "The controller will save you from water logging. controller will save you from water issue.",
  },
  {
    id: "2",
    title: "Dashboard 2",
    image:
      "https://i.pinimg.com/564x/fc/12/4b/fc124b9da4b20d092d30d79642afe196.jpg",
    price: 35000,
    size: "47-59”",
    description:
      "The controller will save you from water logging. controller will save you from water issue.",
  },
];

export const ProductContext = createContext({
  products: initialData,
});

const useProducts = () => {
  const { products } = useContext(ProductContext);

  return { products };
};

export default useProducts;
