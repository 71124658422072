import CommonUserHome from './CommonUserHome';
import AdminUserHome from './AdminUserHome'
export default {
    getComponent: (userType) => {
        switch(userType) {
            case 'Admin':
                return AdminUserHome;
            default:
                return CommonUserHome;
        }
    }
}; 