import {useState, useEffect } from "react";
import { useAuth } from "./useAuth";
import { LOCATION_RETRIEVE_API, LOCATION_REGISTER_API, LOCATION_DEREGISTER_API, DEVICE_OPERATION_API} from "../constants/AWSApiUrlConstant";



const useControllers = () => {
  
  
  const {userToken, apiCurrentUser}  = useAuth();
  const [location, setLocation] = useState(); 
  let CONTROLLER_RETRIEVE_API = LOCATION_RETRIEVE_API.concat(apiCurrentUser.username).concat('/');
  let CONTROLLER_REGISTER_PATH = LOCATION_REGISTER_API.concat(apiCurrentUser.username).concat('/');
  let CONTROLLER_DEREGISTER_PATH = LOCATION_DEREGISTER_API.concat(apiCurrentUser.username).concat('/');

  let headerInfo = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'JWT'+ userToken,
  }

  // useEffect(() => {
  //   loadControllers(locationName);
  // }, []);

  const loadControllers = async (location, callback) => {
    let CONTROLLER_API = CONTROLLER_RETRIEVE_API.concat(location);

    let response = await fetch(CONTROLLER_API, {
        method:'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'JWT'+ userToken,
        }
      });

      let json = await response.json();
      await setLocation(json);
      
      if(typeof callback === 'function'){
        callback(json);
    }
  }


  const registerController = async (location, deviceId) => {
    let CONTROLLER_API = CONTROLLER_REGISTER_PATH.concat(location).concat('/').concat(deviceId);
    try {
      let response = await fetch(CONTROLLER_API, {
        method:'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'JWT'+ userToken,
        }
      });

      let json = await response.json();
    } catch (error) {
      
    }
  }

  const deregisterController = async(location, deviceId) => {

    let CONTROLLER_API = CONTROLLER_DEREGISTER_PATH.concat(location).concat('/').concat(deviceId);

    let response = await fetch(CONTROLLER_API, {
        method:'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'JWT'+ userToken,
        }
      });

      let json = await response.json();
  }

  const deviceStatusController = async (deviceId, deviceStatus) => {
    let CONTROLLER_API = DEVICE_OPERATION_API;


    let content = JSON.stringify({
      "deviceType":"Controller",
      "deviceId":deviceId,
      "deviceStartStop":deviceStatus ? 1 : 0,
      "deviceCommunicationStatus":"1",
      "username":apiCurrentUser.username,
      "startStopTime":"02:31:AM"
    });

    try {
      let response = await fetch(CONTROLLER_API, {
        method:'post',
        headers: headerInfo,
        body: content
      });

      let json = await response.json();
    } catch (error) {

    }
  }

  return { location, loadControllers,  registerController, deregisterController, deviceStatusController};
};

export default useControllers;