
import React, { useEffect } from 'react'
import { createStackNavigator} from '@react-navigation/stack';
import SignupScreen from '../screens/Signup';
import LoginScreen from '../screens/Login';
import ForgetPassword from '../screens/ForgetPassword';
import i18n from 'i18n-js';


const Stack = createStackNavigator();
function AuthStackNavigator() {
  return (
    <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
      <Stack.Screen name="login" component={LoginScreen} options={{
        title: `${i18n.t('login')} | Farming For All`
      }} />
      <Stack.Screen name="signup" component={SignupScreen} options={{
        title: `${i18n.t('signUp')} | Farming For All`
      }} />
      <Stack.Screen name="forgetpassword" component={ForgetPassword} options={{
        title: `${i18n.t('forgotPassword')} | Farming For All`
      }} />
    </Stack.Navigator>
  );
}
export default AuthStackNavigator;