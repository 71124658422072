import React from "react";
import { View, Image, Text } from "react-native";
import {
  DrawerItemList,
  DrawerContentScrollView,
  DrawerItem,
} from "@react-navigation/drawer";
import { useIsFocused } from "@react-navigation/native";
import Colors from "../constants/Colors";
import { useAuth } from "../hooks/useAuth";

const { SafeAreaView } = require("react-native-safe-area-context");

const CustomContentComponent = (props) => {
  return (
    <SafeAreaView
      style={{ flex: 1, height: "100%", backgroundColor: "#ffffff" }}
      forceInset={{ top: "always", horizontal: "never" }}
    >
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          source={require("../assets/images/logo.png")}
          style={{ width: "50%" }}
          resizeMode="contain"
        />
      </View>
    </SafeAreaView>
  );
};

function CustomDrawerContent(props) {
    const {signOut} = useAuth();

  const logoutCallback = () => {
      props.navigation.navigate("login");
  }


  return (
    <DrawerContentScrollView {...props}>
      <CustomContentComponent {...props} />

      <DrawerItemList {...props} />

      <DrawerItem
        label={({ focus, color }) => (
          <Text style={{ color }}>{focus ? "Location" : "Location"}</Text>
        )}
        onPress={() => props.navigation.navigate("LocationBottom")}
      />
      <DrawerItem
        label="Registration"
        onPress={() => props.navigation.navigate("RegistrationBottom")}
      />
      <DrawerItem
        label="Help"
        onPress={() => props.navigation.navigate("HelpBottom")}
      />
      <DrawerItem
        label="Account"
        onPress={() => props.navigation.navigate("AccountBottom")}
      />
      <DrawerItem
        label="Logout"
        onPress={() => signOut(logoutCallback)}
      />
    </DrawerContentScrollView>
  );
}



export default CustomDrawerContent;
