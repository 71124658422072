import React, { useState } from "react";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  View,
} from "react-native";
import { BodyText, InfoText } from "../../components/Text";

import { SendIcon } from "../../components/Icons";
import Colors from "../../constants/Colors";
import TextInput from "../../components/TextInput";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import i18n from 'i18n-js';

const DeviceNumberRegistration = (props) => {
  const [text, setText] = useState('');
  const [showMessage, setShowMessage] = useState();
  const [isError, setError] = useState();
  const [message, setMessage] = useState();
  


  const registration = async() => {
    if(text.length === 0){
      setShowMessage(true);
      setError(true);
      setMessage('Value should not be blank');
      return;
    }


    await props.onPress(text);
  };

  return (
    <View>
      {showMessage && <InfoText error={isError}>{message}</InfoText>}
      <TextInput placeholder={props.placeholder} onChangeText = {v => setText(v)}/>
      <SendIcon
        style={styles.icon}
        size={props.iconSize}
        color={props.iconColor}
        onPress={() => registration()}
      />
    </View>
  );
};

export default DeviceNumberRegistration;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  icon: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

DeviceNumberRegistration.defaultProps = {
  iconSize: 25,
  iconColor: Colors.iconColor,
};
