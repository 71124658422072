import React, { useState } from 'react';
import FlexContainer from '../../components/FlexContainer';
import { useRoute } from "@react-navigation/native";
import SensorDetailData from './SensorDetailData';
import Button from '../../components/Button';
import i18n from 'i18n-js';
import {
    ActivityIndicator
} from "react-native";
import Colors from "../../constants/Colors";
import SensorDataGraph from "./SensorDataGraph";

const CollectorData = (props) => {
    const [canViewSM] = useState(true);
    const route = useRoute();
    const dataType = route.params && route.params.dataType;
    return (
        <FlexContainer direction={'column'}>
            { props.historyData && props.graphView ? <SensorDataGraph {...props} dataType={dataType} /> : null}
            { !props.historyData ?
                <SensorDetailData {...props} dataType={dataType} canViewSM={canViewSM} isEvenRow={true}/> :
                (props.graphView ? null : (props.historyData || []).map((historyData, index) => {
                    return (
                    <SensorDetailData {...historyData} key={historyData.creationDate}
                        isEvenRow = {index % 2 === 0} showDetailedView={props.detailView}
                        dataType={dataType} canViewSM={canViewSM}/>);
                }))
            }
            { props.hasMoreHistory && !props.isFetchingMoreHistory ? <Button
                title={i18n.t('viewMore')}
                onPress={() => {
                    if (props.onLoadMoreHistory) {
                        props.onLoadMoreHistory('SENSOR')
                    }
                }}
                btnStyle={{marginTop: 10, marginBottom: 10, height: 56 }}
                primary
            /> : props.isFetchingMoreHistory ?
            <ActivityIndicator size="large" color={Colors.appColor}  />
            : null }
        </FlexContainer>
    );
};

export default CollectorData;