import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useState, useEffect } from "react";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  View,
  ActivityIndicator,
} from "react-native";

import Header from "../../components/Header";
import { ListItem, Card } from "react-native-elements";
import Colors from "../../constants/Colors";
import { AmplifierIcon } from "../../components/Icons";
import { ScrollView } from "react-native-gesture-handler";
import FFAButton from "../../components/FFAButton";
import Icon from "../../components/Icon";
import useDataCollectors from "../../hooks/useDataCollectors";
import { useIsFocused } from "@react-navigation/native";
import { DefaultTheme } from "../../theme/DefaultTheme";
import i18n from 'i18n-js';

const DataCollectorScreen = () => {
  const route = useRoute();
  const { navigate } = useNavigation();

  const { locationName, controllerId } = route.params;
  
  const { deregisterDataCollector, registerDataCollector, loadCollectors, controller } = useDataCollectors();

  const isFocused = useIsFocused();
  const [isLoading, setLoading] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      try {
        await loadCollectors(locationName, controllerId);
        setLoading(false);
      } catch (e) {
        console.info(e);
      }
    };
    getData();
  }, [isFocused]);

  const handleRegistration = (deviceId) => {
    registerDataCollector(locationName, controllerId, deviceId);
    navigation.goBack();
  };

  const onContollerRegistration = () => {
    navigate("controller-registration", {placeholder:"Enter Data Collector Device Id", title:i18n.t('addDataCollector'), handleRegistration: handleRegistration});
  };

  const handleDeleteDataCollector = async (dataCollectorId) => {
    setLoading(true);
    await deregisterDataCollector(locationName, controllerId, dataCollectorId);
    await loadCollectors(locationName, controllerId);
    setLoading(false);
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
      <Header title={i18n.t('dataCollector')} showBack showLogo showAdd onAdd={onContollerRegistration} />
      <View style={DefaultTheme.container}>
        
        {isLoading ? (<ActivityIndicator size="large" color ={Colors.appColor}/>)
          :
          (<ScrollView>
            {/* <ControllerCard controller={controller} /> */}
          <View style={{ marginVertical: 5 }} />
          <DataCollectorList controller={controller} deleteCollector={handleDeleteDataCollector} />

          </ScrollView>) 
        }
          
        
      </View>
    </SafeAreaView>
  );
};

const ControllerCard = ({ controller }) => {
  const [controllerStatus, setControllerStatus] = useState(false);

  return (
    <Card title={controller && controller.controller}>
      <FFAButton
        icon={<Icon name="code" color="#ffffff" />}
        buttonStyle={{
          borderRadius: 0,
          marginLeft: 0,
          marginRight: 0,
          marginBottom: 0,
        }}
        title={controllerStatus ? "ON" : "OFF"}
      />
    </Card>
  );
};

const DataCollectorList = ({ controller, deleteCollector }) => {
  return (
    <View>
      {controller &&
        controller.collectors.map((item, i) => <DataCollectorItem key={i.toString()} item={item} deleteCollector={deleteCollector} i={i} />)}
    </View>
  );
};

const DataCollectorItem = ({ item ,deleteCollector, i }) => {
  const [checked, setChecked] = useState(item.isOn);
  const [canOnOff] = useState(false);

  const handleDeleteClick =() =>{
    deleteCollector(item.deviceId)
  }

  return (
    <ListItem
      key={i}
      title={item.deviceId}
      leftIcon={<AmplifierIcon onPress={() => alert("hello")} />}
      checkBox={canOnOff ? {
        iconType:"material-community",
        checkedIcon:'lightbulb-on-outline',
        uncheckedIcon:'lightbulb-off-outline',
        checked:checked,
        checkedColor:Colors.iconColor,
        uncheckedColor:"red"
      } : null}
      rightIcon={{
            name:'trash-can-outline',
            type:'material-community',
            color:Colors.deleteIconColor,
            onPress:handleDeleteClick
          }}
      bottomDivider
      chevron={{ color: Colors.appColor }}
    />
  );
};

export default DataCollectorScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    paddingTop: Platform.OS == "ios" ? 20 : StatusBar.currentHeight + 15,
    paddingHorizontal: 25,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  thumbnail: {
    height: 150,
    borderRadius: 10,
    resizeMode: "cover",
  },
});
