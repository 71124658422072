import React from "react";
import { View, Image, Dimensions } from "react-native";

const SplashScreen = () => {

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        background: "#FFF",
        paddingHorizontal: 25,
      }}
    >
      <Image
        source={require("../assets/images/splash.png")}
        style={{ width: (Dimensions.get("screen").width - 100), resizeMode: "contain" }}
      />
    </View>
  );
};

export default SplashScreen;
