import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useState, useEffect } from "react";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  View,
  ActivityIndicator,
  Alert,
} from "react-native";

import Header from "../../components/Header";
import { ListItem, Icon, Card } from "react-native-elements";
import Colors from "../../constants/Colors";
import { AmplifierIcon } from "../../components/Icons";
import { HeaderText } from "../../components/Text";
import { ScrollView } from "react-native-gesture-handler";
import useDataCollectors from "../../hooks/useDataCollectors";
import useControllers from "../../hooks/useControllers";
import { useIsFocused } from "@react-navigation/native";
import { DefaultTheme } from "../../theme/DefaultTheme";
import i18n from 'i18n-js';

const ControllerScreen = () => {
  const { navigate } = useNavigation();
  const route = useRoute();
  const { locationName } = route.params;
  const {registerController, loadControllers, location, deregisterController} = useControllers();
  const isFocused = useIsFocused();
  const [isLoading, setLoading] = useState(false);
  const navigation = useNavigation();


  
  useEffect(() => {
      setLoading(true);
      const getData = async () => {
      try {
        await loadControllers(locationName) ;
        setLoading(false);
      } catch (e) {
        console.info(e);
      }
    };
  getData();
    
  }, [isFocused]);

  const handleRegistration = async(deviceId) => {
    await registerController(location.location, deviceId);
    navigation.goBack();
  }

  const onControllerRegistration = () => {
    navigate("controller-registration", {placeholder:"Enter Controller Device Id", title:i18n.t('addController'), handleRegistration:handleRegistration});
  };

  const handleDeleteController = async (controllerId) => {
    setLoading(true);
    await deregisterController(location.location, controllerId);
    await loadControllers(location.location);
    setLoading(false);
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
      <Header title={i18n.t('controller')} showBack showLogo showAdd onAdd={onControllerRegistration} />
      <View style={DefaultTheme.container}>
        <ScrollView>
          <View style={{ marginVertical: 5 }} />
          {isLoading ? (<ActivityIndicator size="large" color ={Colors.appColor}/>)
            :
            (
              <>
              <HeaderText size={32} style={{ paddingHorizontal: 25 }}>
                {location && location.location}
              </HeaderText>
              <View style={{ marginVertical: 5 }} />
              <ControllerList location={location} deleteController={handleDeleteController} /> 
              </>
            )
          }
          </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const ControllerList = ({location, deleteController}) => {
  return (
    <View>
      {location &&
        location.controllers.map((item, i) => <ControllerItem key={i.toString()} location={location} deleteController={deleteController} item={item} i={i} />)}
    </View>
  );
};

const ControllerItem = ({location, deleteController, item, i }) => {
  const { navigate } = useNavigation();
  const {deviceStatusController} = useControllers();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [checked, setChecked] = useState(item.isOn);

  const ControllerOn = () =>  <Icon name='lightbulb-on' type='material-community' color={Colors.iconColor}/>
  const ControllerOff = () =>  <Icon name='lightbulb-off' type='material-community' color={Colors.iconColor}/>
  
  const buttons = [{ element: ControllerOn }, { element: ControllerOff }]

  const toggleSwitch = (value) => {
    setSwitchValue(value);
  };

  const handleController  = (controller) => {
    navigate("data-collector", {locationName:location.location, controllerId:  controller.deviceId});
  }

  const onSuccessCallback = (controller) => {
    navigate("data-collector", {location:location, controller: controller })
  }
  
  const updateCheckbox = () =>{
    deviceStatusController(item.deviceId, checked);
    setChecked(!checked);
  }

  const updateIndex = (selectedIndex) =>{
    setSelectedIndex(selectedIndex);
    Alert.alert(selectedIndex+'');
  }

  const handleDeleteClick = () =>{
      deleteController(item.deviceId);
  }
  
  return (
    <>
    <ListItem
      key={item.id}
      title={item.deviceId}
      pad={20}
      leftIcon={<AmplifierIcon onPress={() => alert("hello")} />}
      bottomDivider
      badge={{
            value: item.count,
            status: "success",
            textStyle: { color: "#FFFFFF" },
            badgeStyle: {backgroundColor: Colors.appColor}
      }}
      checkBox={{
        iconType:"material-community",
        checkedIcon:'lightbulb-on-outline',
        uncheckedIcon:'lightbulb-off-outline',
        checked:checked,
        onPress:updateCheckbox,
        checkedColor:Colors.iconColor,
        uncheckedColor:"red"
      }}
      rightIcon={{
            name:'trash-can-outline',
            type:'material-community',
            color:Colors.deleteIconColor,
            onPress:handleDeleteClick
          }}
      chevron={{ color: Colors.appColor }}
      onPress={() => {
        handleController(item);
      }}
    />
    </>  


    
  );
};

export default ControllerScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    paddingTop: Platform.OS == "ios" ? 20 : StatusBar.currentHeight + 15,
    paddingHorizontal: 25,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  thumbnail: {
    height: 150,
    borderRadius: 10,
    resizeMode: "cover",
  },
});
