import React from "react";
import Swiper from "react-native-web-swiper";
import { View, Text, StyleSheet, Dimensions, Image } from "react-native";
import { ScrollView } from "react-native-gesture-handler";




const { width, height } = Dimensions.get("window");

const FFASwiper = () => {
  return (
    <View style={{ flex: 1, height:150}}>
      <Swiper
        index={1}
        showsButtons={true}
        loop
        horizontal={false}
        autoplay
        width={width}
        height={200}
        controlsProps={{
          prevTitle: "",
          prevPos: false,
          nextTitle: "",
          dotsTouchable: true,
        }}
      >
        <View style={[styles.slideContainer]}>
          <Image
            resizeMode="stretch"            
            style={styles.image}
            source={require("../assets/images/Banner/banner2.jpg")}
          />
        </View>
        <View style={[styles.slideContainer]}>
          <Image
            resizeMode="stretch"            
            style={styles.image}
            source={require("../assets/images/Banner/banner1.jpg")}
          />
        </View>
        <View style={[styles.slideContainer]}>
          <Image
            resizeMode="stretch"
            style={styles.image}
            source={require("../assets/images/Banner/banner3.jpg")}
          />
        </View>
        <View style={[styles.slideContainer]}>
          <Image
            resizeMode="stretch"
            style={styles.image}
            source={require("../assets/images/Banner/banner4.jpg")}
          />
        </View>
        <View style={[styles.slideContainer]}>
          <Image
            resizeMode="stretch"
            style={styles.image}
            source={require("../assets/images/Banner/banner5.jpg")}
          />
        </View>
      </Swiper>
    </View>
  );
};

export default FFASwiper;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  slideContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width:"100%",
    flex:1,  
  },
});
