import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import Header from "../components/Header";
import React from "react";
import { Platform, Image, View, Text, Linking, TouchableOpacity } from "react-native";


import HomeScreen from "../screens/Home";
import AccountScreen from "../screens/Account";
import Colors from '../constants/Colors';
import LocationScreen from "../screens/location/LocationScreen";
import {HomeIcon,LocationIcon, DashboardIcon, HelpIcon, ProfileIcon, QRCodeIcon} from '../components/Icons';
import i18n from 'i18n-js';
import { SafeAreaView } from "react-native-safe-area-context";
import { DefaultTheme } from "../theme/DefaultTheme";
import SchedularStackNavigator from "../screens/schedular/SchedularStackNavigator";
import DataDashboard from '../screens/DataDashboard';
import ReadOnlyValue from '../components/ReadOnlyValue';



const BottomTab = createBottomTabNavigator();
const INITIAL_ROUTE_NAME = "HomeBottom";





function HelpScreen() {
  const supportEmail = 'support@farmingforall.in';
  const supportNumber = '+91 9899655152';
  return (
    <SafeAreaView style={DefaultTheme.safeAreaContainer}>
    <Header showMenu showNotification />
    <View style={{
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginTop: 20,
      paddingTop: 10,
      paddingBottom: 10
    }}>
      <TouchableOpacity onPress={() => Linking.openURL(`mailto:${supportEmail}`)}><ReadOnlyValue label={`Send email to ${supportEmail} to get technical support`}/></TouchableOpacity>
      <ReadOnlyValue label={'OR'} />
      <TouchableOpacity onPress={() => Linking.openURL(`tel:${supportNumber}`)}><ReadOnlyValue label={`Send whatsapp message on ${supportNumber}`}/></TouchableOpacity>
    </View>
    </SafeAreaView>
  );
}

function RegisterScreen() {
  return (
    <SafeAreaView style={DefaultTheme.safeAreaContainer}>
      <Header showMenu />
    
    </SafeAreaView>
  );
}

const BottomTabNavigator = ({ navigation, route }) => {
  //navigation.setOptions({ headerTitle: getHeaderTitle(route) });

  return (
    <BottomTab.Navigator
      initialRouteName={INITIAL_ROUTE_NAME}
      tabBarOptions={{
        style: Platform.OS != "ios" && { height: 55 },
        //showLabel: true,
        activeTintColor: Colors.tabIconSelected,
        inactiveTintColor: Colors.inactiveTintColor,
      }}
    >


      <BottomTab.Screen
        name="HomeBottom"
        component={HomeScreen}
        options={{
          tabBarLabel:i18n.t('home'),
          tabBarIcon: ({ focused }) => 
            <HomeIcon focused={focused}/>,
        }}
      />
       <BottomTab.Screen
        name="LocationBottom"
        component={LocationScreen}
        options={{
          tabBarLabel:i18n.t('location'),
          tabBarIcon: ({ focused }) => 
            <LocationIcon focused={focused}/>,
        }}
      />

      {/* <BottomTab.Screen
        name="Schedular"
        component={SchedularStackNavigator}
        options={{
          tabBarLabel:"Schedular",
          tabBarIcon: ({ focused }) => 
            <LocationIcon focused={focused}/>,
        }}
      />  */}

        {/* <BottomTab.Screen
        name="RegistrationBottom"
        component={RegisterScreen}
        options={{
          tabBarLabel:i18n.t('registration'),
          tabBarIcon: ({ focused }) => 
            <QRCodeIcon focused={focused}/>,
        }}
      />  */}
      <BottomTab.Screen
        name="AccountBottom"
        component={AccountScreen}
        options={{
          tabBarLabel:i18n.t('account'),
          tabBarIcon: ({ focused }) => 
            <ProfileIcon focused={focused}/>
          
        }}
      />
      <BottomTab.Screen
        name="DataBottom"
        component={DataDashboard}
        options={{
          tabBarLabel:i18n.t('data'),
          tabBarIcon: ({ focused }) => 
            <DashboardIcon focused={focused}/>,
        }}
      />
      <BottomTab.Screen
        name="HelpBottom"
        component={HelpScreen}
        options={{
          tabBarLabel:i18n.t('help'),
          tabBarIcon: ({ focused }) => 
            <HelpIcon focused={focused}/>,
        }}
      />
    </BottomTab.Navigator>
  );
};

export default BottomTabNavigator;