import React from 'react';
import FlexContainer from '../../components/FlexContainer';
import { isDesktop } from "../../constants/common";
import ReadOnlyValueWithImage from '../../components/ReadOnlyValueWithImage';
import i18n from 'i18n-js';
import Constants from './constants';
import Colors from "../../constants/Colors";
const getFormattedDateTime = (date) => {
    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    let datePart = date.getDate();
    datePart = datePart < 10 ? `0${datePart}` : datePart;
    return `${datePart}/${month}/${date.getFullYear()} ${date.toLocaleTimeString()}`;
};

const DataComponent = ({ labelKey, value, hasIcon, iconProps }) => {
    return (
        <FlexContainer
            flex={1}
            justifyContent={'center'}
            marginLeft={10}
            marginRight={0}
            marginBottom={0}>
            <ReadOnlyValueWithImage
                hasIcon={hasIcon}
                iconProps={iconProps}
                noTopMargin={true}
                boldValue={false}
                label={i18n.t(labelKey)}
                value={value}
                labelColor={Constants.blackColor}
                labelSize={Constants.cardLabelFontSize}
                valueColor={Constants.blackColor}
                valueSize={Constants.cardValueFontSize}/>
        </FlexContainer>
    );
};

const ControllerStateSimpleSnapshot = ({
    history
}) => {
    return (
        <FlexContainer padding={0} direction={'column'}>
            <FlexContainer padding={0} direction={'column'}>
                    {(history ||[]).map((data, index) => {
                        const backgroundColor = index % 2 > 0 ? Constants.rowAlternateColor : ''
                        const serverDate = new Date(data.creationDateTime + 'Z');
                        const date = getFormattedDateTime(serverDate);
                        return (
                            <FlexContainer key={index}
                                borderBottomWidth={1}
                                borderColor={'#0000001f'}
                                backgroundColor={backgroundColor}>
                                { isDesktop ? <DataComponent labelKey='location' value={data.farmName}/> : null }
                                { isDesktop ? <DataComponent labelKey='controller' value={data.deviceId}/> : null }
                                <DataComponent labelKey='date' value={date}/>
                                <DataComponent labelKey='status'
                                    value={data.isOn ? i18n.t('onStatus') : i18n.t('offStatus')}
                                    hasIcon={true}
                                    iconProps={{
                                        iconSize: 30,
                                        iconName: data.isOn ? 'lightbulb-on-outline' : 'lightbulb-off-outline',
                                        iconColor: data.isOn ? Colors.iconColor : 'red'
                                    }}/>
                            </FlexContainer>
                        );
                    })}
            </FlexContainer>
        </FlexContainer>
    );
};

export default ControllerStateSimpleSnapshot;