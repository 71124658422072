import React from 'react';
import FlexContainer from '../../components/FlexContainer';

const UserDetailTemplate = () => {
    return (
        <FlexContainer>
            
        </FlexContainer>
    );
};

export default UserDetailTemplate;