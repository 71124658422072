import React, { useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { ListItem } from "react-native-elements";
import { View, Picker, StyleSheet } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { DefaultTheme } from "../../theme/DefaultTheme";
import Header from "../../components/Header";
import i18n from "i18n-js";
import Colors from "../../constants/Colors";
import TextInput from "../../components/TextInput";
import { P } from "../../components/typography";

const AddSchedular = (props) => {
  const [title] = useState(i18n.t("addSchedule"));

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
      <Header showBack title={title} showNotification />
      <View style={DefaultTheme.container}>
        <ScrollView>
          <View style={styles.timeContainer}>
            <TimeModePicker />
            <HoursPicker />
            <MinutePicker />
          </View>
          <ListItem
            pad={16}
            key={"1"}
            title="Repeat"
            topDivider
            bottomDivider
            chevron={{ color: Colors.appColor }}
          />

          <ListItem
            pad={16}
            key={"2"}
            title="Duration (In mins)"
            bottomDivider
            rightElement={<TextInput />}
          />
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const DurationPicker = () => {
  const [selectedValue, setSelectedValue] = useState("0");
  return (
    <Picker
      selectedValue={selectedValue}
      style={{ height: 50, width: 100 }}
      onValueChange={(itemValue, itemIndex) => setSelectedValue(itemValue)}
    >
      <Picker.Item label="0" value="0" />
      <Picker.Item label="1" value="1" />
    </Picker>
  );
};

const TimeModePicker = () => {
  const [selectedValue, setSelectedValue] = useState("0");
  return (
    <View>
      <P></P>
      <Picker
        selectedValue={selectedValue}
        style={{ height: 50, width: 120 }}
        onValueChange={(itemValue, itemIndex) => setSelectedValue(itemValue)}
      >
        <Picker.Item label="AM" value="AM" />
        <Picker.Item label="PM" value="PM" />
      </Picker>
    </View>
  );
};

const HoursPicker = () => {
  const [selectedValue, setSelectedValue] = useState("0");
  return (
    <View>
      <P>Hours</P>
      <Picker
        selectedValue={selectedValue}
        style={{ height: 50, width: 120 }}
        onValueChange={(itemValue, itemIndex) => setSelectedValue(itemValue)}
      >
        <Picker.Item label="0" value="0" />
        <Picker.Item label="1" value="1" />
        <Picker.Item label="2" value="2" />
        <Picker.Item label="3" value="3" />
        <Picker.Item label="4" value="4" />
        <Picker.Item label="5" value="5" />
        <Picker.Item label="6" value="6" />
        <Picker.Item label="7" value="7" />
        <Picker.Item label="8" value="8" />
        <Picker.Item label="9" value="9" />
        <Picker.Item label="10" value="10" />
        <Picker.Item label="11" value="11" />
        <Picker.Item label="12" value="12" />
      </Picker>
    </View>
  );
};

const MinutePicker = () => {
  const [selectedValue, setSelectedValue] = useState("0");
  return (
    <View>
      <P>Minute</P>
      <Picker
        selectedValue={selectedValue}
        style={{ height: 50, width: 120 }}
        onValueChange={(itemValue, itemIndex) => setSelectedValue(itemValue)}
      >
        <Picker.Item label="1" value="1" />
        <Picker.Item label="2" value="2" />
        <Picker.Item label="3" value="3" />
        <Picker.Item label="4" value="4" />
        <Picker.Item label="5" value="5" />
        <Picker.Item label="6" value="6" />
        <Picker.Item label="7" value="7" />
        <Picker.Item label="8" value="8" />
        <Picker.Item label="9" value="9" />
        <Picker.Item label="10" value="10" />
        <Picker.Item label="11" value="11" />
        <Picker.Item label="12" value="12" />
      </Picker>
    </View>
  );
};

export default AddSchedular;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 40,
    alignItems: "center",
  },
  timeContainer: {
    flex: 1,
    flexDirection: "row",
  },
});
