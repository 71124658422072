const tintColor = '#2f95dc';
const commonColor = "#00a859";

export default {
  backgroundColor : "#ffffff",
  appColor:commonColor,
  activityColor:commonColor,
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: "#00a859",
  inactiveTintColor:"#BBBBBB",
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804', 
  noticeBackground: tintColor,
  noticeText: '#fff',
  iconColor: "#00a859",
  deleteIconColor: "#FF0000",
  borderColor: commonColor,
  successColor: "#2b611c",
  errorColor: "#FF0000",
};
