import React, { useState } from 'react';
import { View, ActivityIndicator } from "react-native";
import UserListFilter from './UserListFilter';
import Colors from "../../constants/Colors";
import FlexContainer from '../../components/FlexContainer';
import { useDashboard } from "../../hooks/useDashboard";
import { ScrollView } from "react-native-gesture-handler";
import UserDetailTemplate from './UserDetailTemplate';

const UserDashboard = () => {
    const {
        usersData,
        onFilterCriteriaChange
    }  = useDashboard();
    const [isFilterReady, setFilerReady] = useState(false);
    const onFilterChange = (criteria) => {
        if (onFilterCriteriaChange) {
            onFilterCriteriaChange(criteria);
        }
    };

    return (
        <View>
            <UserListFilter onFilterChange={onFilterChange} onFilterReady={setFilerReady}/>
            { !isFilterReady ? <ActivityIndicator size="large" color={Colors.appColor}  /> :
            <FlexContainer padding={0} direction={'column'}>
                 <ScrollView
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                    removeClippedSubviews={false}
                    >
                    {(usersData || []).map(user => {
                        return <UserDetailTemplate {...user} />;
                    })}
                </ScrollView>
            </FlexContainer>}
        </View>
    );
};

export default UserDashboard;