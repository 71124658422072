import React from 'react';
import OAuthButton from './OAuthButton';
import { Auth } from 'aws-amplify';
import { View } from 'react-native';


function SocialLogin() {
    return (
      <View style={{flex:1, flexDirection:"row",  justifyContent:"space-between"}}>
            <OAuthButton google onPress = {() => Auth.federatedSignIn({provider: "Google"})} />
            <OAuthButton facebook onPress = {() => Auth.federatedSignIn({provider: "Facebook"})} />
      </View>
    )  
}

export default SocialLogin;