export default class Helper {

    static isEmailValid(email) {
       let reg =  /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
       return reg.test(email);
  }

  static isDateValid(date) {
    let reg =/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
    return reg.test(date);
  }

  static isValidCharacter(userName) {
    // let userNameChar = /^([a-zA-Z\-0–9][\]._)$/;
    let reg = /^(?=.{4,20}$)(?:[a-zA-Z\d]+(?:(?:\.|-|_)[a-zA-Z\d])*)+$/g;
    return reg.test(userName);
  }
}