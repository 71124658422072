import Auth from 'aws-amplify'

// const awsConfig = {
//     identityPoolId: 'ap-south-1:442d7325-8843-48d8-97b0-4dd4e2a26cea',
//     region: 'AP_SOUTH_1',
//     userPoolId: 'ap-south-1_ygOBIOKUu',
//     userPoolWebClientId: '5asl8o7lmoakat42jptist8hie'
// }




const awsConfig = {
    Auth : {
        identityPoolId: 'ap-south-1:442d7325-8843-48d8-97b0-4dd4e2a26cea',
        region: 'AP_SOUTH_1',
        userPoolId: 'ap-south-1_rDmNnS8ZW',
        userPoolWebClientId: '2bg238v493e0eq29jeuiq9puu9',
        oauth: {
            domain: 'farmer-for-all-dev-test.auth.ap-south-1.amazoncognito.com/',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'exp://127.0.0.1:19000/',
            redirectSignOut: 'exp://127.0.0.1:19000/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    
    }
}

// const oauth = {
//     // Domain name
//     domain : 'your-domain-prefix.auth.us-east-1.amazoncognito.com', 

//     // Authorized scopes
//     scope : ['phone', 'email', 'profile', 'openid','aws.cognito.signin.user.admin'], 

//     // Callback URL
//     redirectSignIn : 'farmingforall://root/', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'

//     // Sign out URL
//     redirectSignOut : 'http://www.example.com/signout/', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'

//     // 'code' for Authorization code grant, 
//     // 'token' for Implicit grant
//     responseType: 'code',

//     // optional, for Cognito hosted ui specified options
//     options: {
//         // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
//         AdvancedSecurityDataCollectionFlag : true
//     }
// }


export default awsConfig;