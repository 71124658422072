import React from "react";
import { View, Text } from "react-native";
import Colors from "../constants/Colors";

const MuliText = (props) => {
  return (
    <Text
      {...props}
      style={[
        props.style,
        {
          color: props.color || "#0ed145",
          fontFamily: "muli",
          fontSize: props.size ? props.size : 12,
          textAlign: props.center ? "center" : props.right ? "right" : props.left ? "left":"left",
        },
      ]}
    />
  );
};

const PlayfairText = (props) => {
  return (
    <Text
      {...props}
      style={[
        props.style,
        {
          color: !props.color ? (props.inverted ? "#FFF" : "#00a859") : props.color,
          fontFamily: props.bold ? "playfair-semibold" : "playfair-regular",
          fontSize: props.size ? props.size : 16,
          textAlign: props.center ? "center" : props.right ? "right" : props.left ? "left":"left",
        },
      ]}
    />
  );
};

export const HeaderText = (props) => {
  return <PlayfairText {...props} bold color="#00a859" size={props.size ?  props.size : 36} />;
};

export const BodyText = (props) => {
  return props.bold ? <PlayfairText {...props} /> : <MuliText {...props} />;
};


export const InfoText = (props) => {
  return props.error ? <ErrorText {...props}  /> : <SuccessText {...props} />
}

// export const ErrorText = (props) => {
//   return (
    
//     <Text
//       {...props}
//       style={[
//         props.style,
//         {
//           color: Colors.errorColor,
//           backgroundColor:"#ffffff",
//           fontFamily: "playfair-semibold",
//           borderWidth:1,
//           borderColor:Colors.borderColor,
//           borderRadius: 6,
//           fontSize: props.size ? props.size : 12,
//           textAlign: "center",
//           shadowColor: '#888888',
//           shadowOffset: { width: 1, height: 2 },
//           shadowOpacity: 1,
//           shadowRadius: 1, 
//           elevation: 10, 
//         },
//       ]}
//     />
    
//   );
// }

// export const SuccessText = (props) => {
//   return (
//     <Text
//       {...props}
//       style={[
//         props.style,
//         {
//           color: Colors.successColor,
//           backgroundColor:"#ffffff",
//           fontFamily: "playfair-semibold",
//           borderWidth:1,
//           borderColor:Colors.borderColor,
//           borderRadius: 6,
//           fontSize: props.size ? props.size : 12,
//           textAlign: "center",
//           shadowColor: '#888888',
//           shadowOffset: { width: 1, height: 2 },
//           shadowOpacity: 1,
//           shadowRadius: 1, 
//           elevation: 10, 
//         },
//       ]}
//     />
//   );
// }
export const ErrorText = (props) => {
  return (
    
    <Text
      {...props}
      style={[
        props.style,
        {
          color: Colors.errorColor,
          backgroundColor:"#ffffff",
          fontFamily: "playfair-semibold",
          borderRadius: 6,
          fontSize: props.size ? props.size : 12,
          textAlign: "center",
          
        },
      ]}
    />
    
  );
}

export const SuccessText = (props) => {
  return (
    <Text
      {...props}
      style={[
        props.style,
        {
          color: Colors.successColor,
          backgroundColor:"#ffffff",
          fontFamily: "playfair-semibold",
          borderRadius: 6,
          fontSize: 12,
          textAlign: "center",
        },
      ]}
    />
  );
}

export const PriceText = (props) => {
  return (
    <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
      <PlayfairText
        style={
          ({ color: props.inverted && "#FFF" }, props.size > 24 && { top: 5 })
        }
      >
      {'\u20A8' }
      </PlayfairText>
      <MuliText
        size={props.size && props.size}
        style={{ color: props.inverted && "#FFF" }}
      >
        {props.value}
      </MuliText>
    </View>
  );
};
