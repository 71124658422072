import { MaterialCommunityIcons, AntDesign, FontAwesome5 } from '@expo/vector-icons';
import * as React from 'react';

import Colors from '../constants/Colors';

const  MaterialCommunityIcon  = (props) => {
  return  (
    <MaterialCommunityIcons
      name={props.name}
      size={props.size ? props.size : 24}
      style={{ marginBottom: -3 }}
      color={Colors.iconColor}
    />
  );
}


const  AntDesignIcon  = (props) => {
  return  (
    <AntDesign 
      name={props.name}
      size={props.size ? props.size : 24}
      style={{ marginBottom: -3 }}
      color={Colors.iconColor}
    />
  );
  
}

const ThemeIcon = (props) => { return <AntDesignIcon />};



export default function Icon(props) {
  return (

    

    <FontAwesome5
    name={props.name}
    size={props.size ? props.size : 24}
    style={[props.style, { marginBottom: -3, marginRight: 3}] }
    color={Colors.iconColor}
    onPress={props.onPress}
  />
  );
}