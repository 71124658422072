import React from 'react';
import { View, Image } from "react-native";
import ReadOnlyValue from './ReadOnlyValue';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const ReadOnlyValueWithImage = (props) => {
    const showInCoulmn = props.direction !== 'row';
    return (
        <View style={{
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-around',
            flexDirection: 'row',
            paddingTop: 10,
            paddingBottom: 10
         }}>
            { props.hasIcon ? <MaterialCommunityIcons style={
                {
                    width: 36,
                    height: 36,
                    marginRight: 5,
                    marginLeft: props.marginLeft || 0,
                    marginTop: showInCoulmn ? 15 : 0
                }
            } name={props.iconProps.iconName}
            size={props.iconProps.iconSize}
            color={props.iconProps.iconColor}
            onPress={props.iconProps.onIconClick}/> : null } 
            { props.imageSrc ? <Image source={props.imageSrc} style={
                {
                    width: 36,
                    height: 36,
                    marginRight: 5,
                    marginLeft: props.marginLeft || 0,
                    marginTop: showInCoulmn ? 15 : 0
                }
            }/> : null }
            <ReadOnlyValue {...props}/>
        </View>
    );
};

export default ReadOnlyValueWithImage;