import React from 'react';
import { View } from "react-native";
import { LinearGradient } from 'expo-linear-gradient';
import { isDesktop } from "../constants/common";

const FlexContainer = (props) => {
    const Comp = props.gradientColors ? LinearGradient : View;
    return (
        <Comp colors={props.gradientColors} start={null} style={[{
            display: 'flex',
            alignItems: props.alignItems || 'stretch',
            justifyContent: props.justifyContent || 'space-between',
            flexDirection: props.direction || (isDesktop ? 'row' : 'column'),
            borderColor: props.borderColor || 'transparent',
            borderStyle: props.borderStyle || 'solid',
            flex: props.flex,
            padding: props.padding || 0,
            margin: props.margin || 0,
            marginLeft: props.marginLeft || 0,
            marginRight: props.marginRight || 0,
            marginTop: props.marginTop || 0,
            marginBottom: props.marginBottom || 0,
            paddingLeft: props.paddingLeft || 0,
            paddingRight: props.paddingRight || 0,
            paddingTop: props.paddingTop || 0,
            paddingBottom: props.paddingBottom || 0,
            borderLeftWidth: props.borderLeftWidth || 0,
            borderRightWidth: props.borderRightWidth || 0,
            borderTopWidth: props.borderTopWidth || 0,
            borderBottomWidth: props.borderBottomWidth || 0,
            borderRadius: props.borderRadius || 0,
            backgroundColor: props.backgroundColor || ''
        }, props.style]}>
            {props.children}
        </Comp>
    );
};

export default FlexContainer;