import React from "react";
import { StyleSheet, View, TouchableOpacity, Image } from "react-native";

import { useAuth } from "../hooks/useAuth";

import { BodyText } from "./Text";
import Colors from "../constants/Colors";
import i18n from 'i18n-js';

const OAuthButton = ({ google, onPress }) => {
  const { signInAsyncGoogle } = useAuth();

  return (
    <TouchableOpacity
      onPress={onPress}
      style={styles.container}
    >
      <View style={{ marginHorizontal: 6 }} />
      <Image
        source={
          google
            ? require("../assets/images/google.png")
            : require("../assets/images/facebook.png")
        }
        style={styles.icon}
      />
      <View style={{ marginHorizontal: 6 }} />
      <BodyText center style={{ flex: 1 }}>
        {google ? i18n.t('google') : i18n.t('facebook')}
      </BodyText>
      <View style={{ marginHorizontal: 10 }} />
    </TouchableOpacity>
  );
};

export default OAuthButton;

const styles = StyleSheet.create({
  container: {
    flex: 0.48,
    flexDirection: "row",
    alignItems: "center",
    justifyContent:"space-between",
    borderWidth: 1,
    borderColor: Colors.borderColor,
    borderRadius: 10,
    paddingVertical: 10,
    marginVertical: 10,
  },
  icon: {
    width: 24,
    height: 24,
  },
});
