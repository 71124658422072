import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";

import  AddSchedular  from './AddSchedular';
import SchedularScreen from './SchedularScreen';


const Stack = createStackNavigator();

const SchedularStackNavigator = () => {

    return (
        <Stack.Navigator
          screenOptions={{
          headerShown: false,
        }}
      >
            <Stack.Screen name ="schedularlist" component={SchedularScreen} />
            <Stack.Screen name ="addSchedular" component={AddSchedular} />
        </Stack.Navigator>
    );
    
}

export default SchedularStackNavigator;