import React, { useState, useEffect } from "react";
import {
  SafeAreaView,
  View,
  ActivityIndicator,
} from "react-native";
import { LocationIcon } from "../components/Icons";
import Colors from "../constants/Colors";
import useAddress from "../hooks/useAddress";
import { useIsFocused } from "@react-navigation/native";

import { useNavigation, useRoute } from "@react-navigation/native";
import { ScrollView } from "react-native-gesture-handler";

import { HeaderText, BodyText } from "../components/Text";
import Header from "../components/Header";

import { useAuth } from "../hooks/useAuth";
import FFAButton from "../components/FFAButton";
import i18n from 'i18n-js';
import { DefaultTheme } from "../theme/DefaultTheme";
import { ListItem, Card } from "react-native-elements";


const AddressesScreen = () => {
  const { navigate } = useNavigation();
  const { addresses, loadAddresses } = useAddress();
  const isFocused = useIsFocused();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      try {
        await loadAddresses();
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    getData();
  }, [isFocused]);

  const handleOnNewAddress = (address) => {
    handleAddress({});
  };

  const handleAddress = (address) => {
    navigate("shipping-address", {
      address: address,
    });
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
    <Header title={i18n.t('yourAddresses')} showBack />
    <View style={DefaultTheme.container}>
      <View style={{ marginVertical: 5 }} />
      <ScrollView showsVerticalScrollIndicator={false}>
      <FFAButton title={i18n.t('addAddress')}  onPress={handleOnNewAddress} containerStyle={{paddingHorizontal:15}}/>
      
        {isLoading ? (
              <ActivityIndicator size="large" color={Colors.appColor} />
            ) : (
              <>
                {addresses &&
                  addresses.map((address, index) => (
                    <AddressCardComponent
                      key={index.toString()}
                      index={index}
                      address={address}
                      onPress={() => handleAddress(address)}
                    />
                  ))}
              </>
            )}
      </ScrollView>
    </View>
  </SafeAreaView>
  );
};



const AddressCardComponent = ({ address, index, onPress }) => {
  return (
    <Card
      key={index.toString()}
      containerStyle={DefaultTheme.cardContainerStyle}
    >
      <BodyText bold={true}>{address.addressName}</BodyText>
      <BodyText>{address.houseNo}</BodyText>
      <BodyText>{address.street}</BodyText>
      <BodyText>
        {address.city},{address.state} {address.zip}
      </BodyText>
      <View style={{ marginVertical: 2 }} />
      <BodyText>{address.country}</BodyText>
      <AddressActionComponent onPress={onPress} />
    </Card>
  );
};

const AddressActionComponent = ({ onPress }) => {
  return (
    <View
      style={{ flex: 1, flexDirection: "row", justifyContent: "space-around" }}
    >
      <FFAButton title={i18n.t('edit')} onPress={onPress} />
      <FFAButton title={i18n.t('remove')} onPress={onPress} />
    </View>
  );
};

export default AddressesScreen;